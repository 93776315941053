import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Tariffs } from "../../types/tariffs";
import { getTariffs } from "../../api/tariffs";
import { RootState } from "../store";


type InitialState = {
  tariffs: Tariffs;

}

const initialState: InitialState = {
  tariffs: {
    "tariffs.tier.three": [],
    "tariffs.tier.two": [],
    "tariffs.tier.one": [],
  },
};

export const getTariffsData = createAsyncThunk(
  'tariffsReducer/getTariffsData',
  async () => {
    return await getTariffs();
  },
);


const tariffsReducer = createSlice({
  name: "tariffsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTariffsData.fulfilled, (state, action) => {
      state.tariffs= action.payload;
    });
  },
});

export const geTariffsData= (state: RootState) => state.tariffsReducer.tariffs;

export default tariffsReducer.reducer;