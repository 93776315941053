import React, { useEffect, useRef, useState } from "react";
import styles from './BlanceRefill.module.scss';
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import copyIcon from "../../../img/copy.svg";
import { Notification } from "../../Notification/Notification";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getCryptoWalletThunk, getUserCryptoWallet } from "../../../store/reducers/financeReducer";
import { useCloseModal } from "../hooks";


type Props = {
  hideModal: () => void;
};


export const BalanceRefill: React.FC<Props> = ({ hideModal }) => {
  const walletRef = useRef<HTMLDivElement>(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const dispatch = useAppDispatch();
  const cryptoWallet = useAppSelector(getUserCryptoWallet);

  useEffect(() => {
    if (!cryptoWallet) {
      dispatch(getCryptoWalletThunk());
    }
  }, [cryptoWallet]);

  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Пополнение баланса</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}

          />
        </div>

        <div className={styles.paymentContainer}>
          <div>
            <div className={styles.currencyTitle}>Криптовалюта</div>
            <div className={styles.currency}>
              <span>USDT TRC20</span>
            </div>

            <div className={styles.currencyTitle}>Криптокошелек</div>
            <div className={styles.currency}>
              <div ref={walletRef}>{cryptoWallet}</div>
              <img
                onClick={() => {
                  if (!walletRef.current) return;
                  navigator.clipboard.writeText(walletRef.current.innerText).then(() => {
                    setPopupVisible(true);
                  });
                }}
                src={copyIcon}
                alt="copy icon"
              />
            </div>
          </div>
          <div className={styles.bottomWrapper} ref={modalRef}>
            <div className={styles.warning}>
              Перед пополнением криптокошелька скопируйте адрес из окна.
              <div className={styles.bold}>Адрес может измениться или кошелек может архивироваться.</div>
              Копируйте адрес перед каждой транзакцией.
            </div>
            <div className={styles.text}>
              При переводе на указанный адресс ваш баланс обновится автоматически. В случае проблем обратитесь в
              <a
                href="https://t.me/yaroslava_support_wwa"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.support}>
                &nbsp;
                Поддержку
              </a>
            </div>
            <Button onClick={hideModal}>Вернуться на главную</Button>
          </div>
        </div>
      </div>
      <Notification
        title="Скопировано"
        visible={popupVisible}
        hideNotification={() => setPopupVisible(false)}
      />
    </div>
  )
}