import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";

import styles from "./SideBar.module.scss";
import pwaIcon from "../../img/sidebar/pwaIcon.svg";
import statisticIcon from "../../img/sidebar/statistic.svg";
import financesIcon from "../../img/sidebar/finances.svg";
import tariffIcon from "../../img/sidebar/tariffs.svg";
import faqIcon from "../../img/sidebar/faq.svg";
import supportIcon from "../../img/sidebar/support.svg";
import pinIcon from "../../img/sidebar/pin.svg";
import notebookIcon from "../../img/sidebar/notebook.svg";
import balanceIcon from "../../img/sidebar/balance.svg";
import pushNotificationIcon from "../../img/sidebar/pushNotification.svg";
import statisticsByClicks from "../../img/sidebar/statisticsByClicks.svg";
import { useAppSelector } from "../../store/hooks";
import { getUserPartners, getUserStatus } from "../../store/reducers/userReducer";

const openLink = (link: string) => (e : React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  window.open(link, "_blank");
}

export const SideBar = () => {
  const adminUser = useAppSelector(getUserStatus);
  const userPartner = useAppSelector(getUserPartners);

  return (
    <div className={styles.sideBar}>
      {[
        { title: "PWA", icon: pwaIcon, to: "/" },
        { title: "Статистика общая", icon: statisticIcon, to: "/statistics" },
        { title: "Статистика по кликам", icon: statisticsByClicks, to: "/clicks-statistics" },
         ...userPartner ? [] : [{ title: "Финансы", icon: financesIcon, to: "/finances" }] ,
        ...adminUser ? [{ title: "Балансы", icon: balanceIcon, to: "/balance" }] : [],
        { title: "Pixel API", icon: pinIcon, to: "/pixel-api" },
        { title: "Документация", icon: notebookIcon, to: "/documentation" },
        { title: "Пуш уведомления", icon: pushNotificationIcon, to: "https://t.me/yaroslava_support_wwa",
        onClick: openLink("https://t.me/yaroslava_support_wwa")},
        { title: "Поддержка", icon: supportIcon, to: "https://t.me/yaroslava_support_wwa",
        onClick: openLink("https://t.me/yaroslava_support_wwa")},
      ].map(link => <NavLink
        key={link.title}
        to={link.to}
        className={({ isActive }) =>
          isActive ? `${styles.active} ${styles.item}` : styles.item
        }
        onClick={link.onClick}
      >
        <Tooltip title={link.title}>
          <img src={link.icon} width={32} height={32} alt={`иконка ${link.title}`}/>
        </Tooltip>
        <span>{link.title}</span>
      </NavLink>)}
    </div>
  );
};
