import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import AccordionMUI from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import styles from "./AccordionFaq.module.scss";


const StyledAccordion = styled(AccordionMUI)`
  &.MuiPaper-root.MuiAccordion-root {
    border-radius: 12px;
  }
  &.MuiAccordion-root {
    &::before {
      content: none;
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: #ececf4;
  padding-top: 32px;
  box-shadow: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 0 0 12px 12px;
`;

type Props = {
  question: string,
  answer?: string,
  controls?: string,
  img?: string,
  points?: string[],

}

export const AccordionFaq: React.FC<Props> = ({ question, answer, img, points }) => {
  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        {question}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {img && <img src={img} alt="printScreen" className={styles.printScreen}/>}
        {answer}
        <div className={styles.points}>
        {points?.map((point, index) => (
          <p key={index}>{point}</p>
        ))}
        </div>

      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
