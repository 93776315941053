import React, { ChangeEvent } from "react";

import styles from "./FormTextArea.module.scss";

type Props = {
  errorMessage?: string;
  placeholder?: string;
  bg?: string;
  value? :string;
  handleChange?: (e: ChangeEvent<HTMLTextAreaElement>)=>void;
};

export const TextArea = ({
  errorMessage,
  placeholder,
  bg,
  value,
  handleChange,

}: Props) => {
  return (
    <>
      <textarea
        style={{
          background: bg || "transparent",
          border: !errorMessage ? "none" : "1px solid red",
          width: "100%",
          height: "97px",
          padding: "12px 16px",
          fontSize: "14px",
          color: "2E2B2B",
        }}
        className={styles.textarea}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      {errorMessage && (
        <p className={styles.error}>{errorMessage}</p>
      )}
    </>
  );
};
