import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { deletePixel, getPixelList } from "../../api/pixelApi";
import { PixelDataGet } from "../../types/pixelApi";
import { getDefaultParams } from "../../api/helpers/scripts";
import { AxiosError } from "axios";
import { ErrorMessage } from "../../types/api";


type InitialState = {
  pixelList: {
    data: PixelDataGet[],
    count: number,
  };
}

const initialState: InitialState = {
  pixelList: {
    data: [],
    count: 0,
  },
};

export const DEFAULT_ROWS_PER_PAGE = 10;

function getPixelParams() {
  const params = new URLSearchParams(window.location.search);
  return getDefaultParams(params, DEFAULT_ROWS_PER_PAGE);
}


export const getPixelListThunk = createAsyncThunk(
  'pixelReducer/getPixelLists',
  async (_,{ rejectWithValue, signal }) => {
    try {
      return await getPixelList(getPixelParams(), signal);
    }catch (err: any) {
      const error = err as AxiosError<ErrorMessage>;
      if (!error.response) {
        throw error
      }
      throw rejectWithValue(error.response!.data);
    }
  },
);



export const deletePixelThunk = createAsyncThunk<void, number>(
  'pixelReducer/deletePixelThunk',
  async (id, { dispatch }) => {
    await deletePixel(id);
    await dispatch(getPixelListThunk());
  },
);

const pixelReducer = createSlice({
  name: "pixelReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPixelListThunk.fulfilled, (state, action) => {
      state.pixelList.data = action.payload.results;
      state.pixelList.count = action.payload.count;
    });
  },
});

export const getUserPixelList = (state: RootState) => state.pixelReducer.pixelList;

export default pixelReducer.reducer;