import ReactSelect, { SingleValue } from 'react-select';

import { StyledSelect } from './Select.styled';

export type SelectThemeType = "normal" | "grey";
export type SelectIconType = "none" | "menu";

type Props = {
  options: { label: string; value: string }[];
  value?: string | null;
  onChange?: (e: SingleValue<{ label: string, value: string }>) => void;
  onBlur?: () => void;
  onSet?: (value: string) => void,
  defaultValue?: { label: string, value: string };
  placeholder?: string;
  theme?: SelectThemeType;
  isvalid?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  icon?: SelectIconType
};

export const Select = ({
  options,
  value,
  onChange,
  onBlur,
  onSet,
  defaultValue,
  placeholder,
  theme = "normal",
  icon = "none",
  isvalid = true,
  isDisabled = false,
  isSearchable = false,
}: Props) => {
  return (
    <StyledSelect
      $theme={theme}
      $isvalid={isvalid}
      $icon={icon}
    >
      <ReactSelect
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        classNamePrefix="styled-select"
        value={options.find(option => option.value === value) || defaultValue || null}
        defaultValue={defaultValue}
        onChange={(e) => {
          if (onSet && e?.value) {
            return onSet(e.value);
          }
          if (onChange) {
            return onChange(e);
          }
        }}
        placeholder={placeholder}
        options={options}
        onBlur={onBlur}
        noOptionsMessage={()=> "Нет вариантов"}
      />
    </StyledSelect>
  );
};
