import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Header.module.scss";
import logoIcon from "../../img/logo.svg";
import { WalletBalance } from "../WalletBalance/WalletBalance";
import { UserHeader } from "../UserHeader/UserHeader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AuthStatus, getAuthStatus, getUserDataThunk } from "../../store/reducers/userReducer";
import layoutStyles from "../MainLayout/MainLayout.module.scss";

export const Header = () => {
  const authStatus = useAppSelector(getAuthStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === AuthStatus.Error) {
      navigate('/login', { replace: true });
    }
  }, [navigate, authStatus]);

  useEffect(() => {
    dispatch(getUserDataThunk());
  }, [dispatch]);

  return (
    <header className={styles.header}>
      <div className={`${styles.content} ${layoutStyles.sidePaddings}`}>
        <img
          src={logoIcon}
          width={69}
          height={28}
          alt="logo icon"
          onClick={()=> navigate("/")}
          className={styles.logo}
        />
        <div className={styles.rightSideContainer}>
          <span>
            <WalletBalance/>
          </span>
          <UserHeader/>
        </div>
      </div>
    </header>
  );
};
