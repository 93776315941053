import React, { useRef, useState } from "react";
import styles from "./LinksModal.module.scss";
import closeIcon from "../../../img/closeIcon.svg";
import copyIcon from "../../../img/copy.svg";
import { Notification } from "../../Notification/Notification";
import { useCloseModal } from "../hooks";

type Props = {
  hideModal: () => void;
  domain?: string;
};
export const LinksModal: React.FC<Props> = ({
  hideModal,
  domain,
}) => {
  const [popupVisible, setPopupVisible] = useState(false);

  const fbLink = `https://${domain}/?pixel_id=PIXEL&sub1={sub1}&sub2={sub2}&sub3={sub3}&sub4={sub4}&sub5={sub5}&sub6={sub6}&sub7={sub7}`;
  const regLink = "https://callback.pwa.wildwildapps.com/postback/?event=lead&tds_clickid=TDS_CLICKID";
  const depLink = "https://callback.pwa.wildwildapps.com/postback/?event=sale&tds_clickid=TDS_CLICKID&payout=PAYOUT";

  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Получение ссылки</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>

        {domain &&
            <div className={styles.contentContainer}>
                <div className={styles.info}>
                    Мы настоятельно рекомендуем проверять корректность создания каждой ссылки перед запуском рекламной
                    кампании.
                    Такая проверка позволит избежать потери трафика и гарантировать эффективность рекламной кампании.
                </div>
                <div className={styles.linksContainer}>
                    <div className={styles.label}>Facebook</div>
                    <div className={styles.input}>
                        <div className={styles.inputInnerText}>{fbLink}</div>
                        <img
                            onClick={() => navigator.clipboard.writeText(fbLink).then(() => {
                              setPopupVisible(true);
                            })}
                            src={copyIcon}
                            alt="copy icon"
                        />
                    </div>
                   <div className={styles.postbacks}>Постбэки</div>

                    <div className={styles.label}>Reg</div>
                    <div className={styles.input}>
                        <div className={styles.inputInnerText}>{regLink}</div>
                        <img
                            onClick={() => navigator.clipboard.writeText(regLink).then(() => {
                              setPopupVisible(true);
                            })}
                            src={copyIcon}
                            alt="copy icon"
                        />
                    </div>

                    <div className={styles.label}>Dep</div>
                    <div className={styles.input}>
                        <div className={styles.inputInnerText}>{depLink}</div>
                        <img
                            onClick={() => navigator.clipboard.writeText(depLink).then(() => {
                              setPopupVisible(true);
                            })}
                            src={copyIcon}
                            alt="copy icon"
                        />
                    </div>
                </div>
            </div>}
      </div>

      <Notification
        title="Ссылка скопирована"
        visible={popupVisible}
        hideNotification={() => setPopupVisible(false)}
      />
    </div>
  )
}