import React, { useEffect } from "react";

import styles from "./Table.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getGeneralStatisticsData,
  getGeneralStatisticsThunk,
} from "../../store/reducers/statisticReducer";
import { ParamsSortIcon } from "../SortIcon/ParamsSortIcon";
import { useSearchParams } from "react-router-dom";
import { ParamsPagination } from "../Pagination/ParamsPagination";
import { getDefaultParams } from "../../api/helpers/scripts";
import { ERROR, ordering as orderingType, params } from "../../api/helpers/constants";


const DEFAULT_ROWS_PER_PAGE = 10

export const GeneralStatisticTable = () => {
  const dispatch = useAppDispatch();
  const statisticsData = useAppSelector(getGeneralStatisticsData);

  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const promise = dispatch(getGeneralStatisticsThunk(getDefaultParams(
      searchParams, DEFAULT_ROWS_PER_PAGE
    )));
    promise.unwrap().catch(error => {
      if (error.detail === ERROR.INVALID_PAGE_ERROR) {
        setSearchParams(_params => {
          _params.set(params.page, '1');
          return _params;
        });
      }
    });

    return () => {
      promise.abort();
    }
  }, [dispatch, searchParams]);

  return (
    <>
      <table className={`${styles.table} ${styles.generalStatisticTable}`}>
        <thead>
        <tr>
          {([
            { ordering: orderingType.pwa, title: "PWA" },
            { ordering: orderingType.domain, title: "Домен" },
            { ordering: orderingType.country, title: "Страна" },
            { ordering: orderingType.clicks, title: "Клики" },
            { ordering: orderingType.installs, title: "Установки" },
            { ordering: orderingType.is_open, title: "Открытия" },
            { ordering: orderingType.leads, title: "Лиды " },
            { ordering: orderingType.sales, title: "Продажи" },
          ] as { ordering: string, title: string }[]).map(column => (
            <th key={column.ordering}>{column.title}
              <ParamsSortIcon
                sortsBy={column.ordering}
              />
            </th>
          ))}
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {statisticsData.data.length > 0 ? statisticsData.data.map((row, index) => (
          <tr key={index + "trow"}>
            <td>{row.pwa}</td>
            <td>{row.domain}</td>
            <td>{row.country}</td>
            <td>{row.clicks}</td>
            <td>{row.installs}</td>
            <td>{row.opens}</td>
            <td>{row.leads}</td>
            <td>{row.sales}</td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
        <tbody>
        <tr>
          <td className={styles.total}>Всего</td>
          <td></td>
          <td></td>
          <td className={styles.total}>{statisticsData.total_clicks}</td>
          <td className={styles.total}>{statisticsData.total_installs}</td>
          <td className={styles.total}>{statisticsData.total_opens}</td>
          <td className={styles.total}>{statisticsData.total_leads}</td>
          <td className={styles.total}>{statisticsData.total_sales}</td>
        </tr>
        </tbody>
      </table>
      <ParamsPagination
        listLength={statisticsData.count}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
      />
    </>
  );
};
