import React, { useEffect } from "react";

import styles from "./Table.module.scss";
import { formatTimestamp } from "../../helpers/formatData";
import { useAppSelector } from "../../store/hooks";
import { DEFAULT_ROWS_PER_PAGE, getTransactions } from "../../store/reducers/financeReducer";
import { useSearchParams } from "react-router-dom";
import { ordering, params } from "../../api/helpers/constants";
import { TransactionPurpose } from "../../types/finance";
import { ParamsPagination } from "../Pagination/ParamsPagination";
import { ParamsSortIcon } from "../SortIcon/ParamsSortIcon";

export const WriteOffTable = () => {
  const transactions = useAppSelector(getTransactions);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(_params => {
      _params.set(params.purpose, TransactionPurpose.write_off);
      return _params;
    });
  }, []);

  return (
    <>
      <table className={`${styles.table} ${styles.transactionsTable}`}>
        <thead>
        <tr>
          <th>Создан<ParamsSortIcon sortsBy={ordering.created_at}/></th>
          <th>Cумма<ParamsSortIcon sortsBy={ordering.amount}/></th>
          <th>Описание</th>
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {transactions.data.length > 0 ? transactions.data.map((tx, index) => (
          <tr key={index + "trow"}>
            <td>{formatTimestamp(tx.created_at)}</td>
            <td className={(Number(tx.amount) < 0) ? styles.negative : styles.blue}>$ {tx.amount}</td>
            <td>{tx.description}</td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
      </table>
      <ParamsPagination
        listLength={transactions.count}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
      />
    </>
  );
};
