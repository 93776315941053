import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import pwaFormReducer from "./reducers/pwaFormReducer";
import pwaListReducer from "./reducers/pwaListReducer";
import globalDataReducer from "./reducers/globalDataReducer";
import financeReducer from "./reducers/financeReducer";
import statisticsReducer from "./reducers/statisticReducer";
import tariffsReducer from "./reducers/tariffsReducer";
import pixelReducer from "./reducers/pixelReducer";
import localizationReducer from "./reducers/localizationReducer";

const rootReducer = combineReducers({
    userReducer,
    pwaFormReducer,
    pwaListReducer,
    globalDataReducer,
    financeReducer,
    statisticsReducer,
    tariffsReducer,
    pixelReducer,
    localizationReducer
});

export default rootReducer;
