import { params } from "./constants";

export function getParamsStringNoPagination(urlParams: URLSearchParams) {
  urlParams.delete(params.limit);
  urlParams.delete(params.page);

  return urlParams;
}

export function getDefaultParams(
  searchParams: URLSearchParams,
  defaultRowsPerPage: number | string,
  ordering?: string,
) {
  const getParams = new URLSearchParams(searchParams);
  if (!getParams.get(params.page)) getParams.set(params.page, '1');
  if (!getParams.get(params.limit)) getParams.set(params.limit, defaultRowsPerPage.toString());
  if (!getParams.get(params.ordering) && ordering) getParams.set(params.ordering, ordering);

  return getParams;
}