import React, { useMemo, useRef, useState } from "react";
import { useFormikContext } from "formik";
import dayjs from "dayjs";

import styles from "./Comments.module.scss";
import { Comment } from "../../../components/Comment/Comment";
import { PwaFormValues } from "../helpers/formikHelpers";
import { PagePreview } from "../../../components/PagePreview/PagePreview";
import { convertDate } from "../../../helpers/formatData";
import { PagesNavigation } from "../PagesNavigation/PagesNavigation";
import addIcon from "../../../img/addIcon.svg";
import { GenerateCommentModal } from "../../../components/Modals/GenerateCommentsModal/GenerateCommentsModal";

export const Comments = () => {
  const { values, errors, setFieldValue } = useFormikContext<PwaFormValues>();

  const contentRef = useRef<HTMLDivElement>(null);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const addCommentHandler = async () => {
    await setFieldValue(`comments.${values.comments.length}`, {
      rating: 5,
      likes: 0,
      author: "",
      text: "",
      date: convertDate(dayjs(new Date()).toString()),
    });
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  };

  const errorsSummary: string[] = useMemo(() => {
    const res: string[] = [];

    if (errors.name) {
      res.push("Название");
    }
    if (errors.tracker) {
      res.push("Трекер");
    }
    if (errors.comments) {
      res.push("Комментарии");
    }
    if (errors.vertical) {
      res.push("Дизайн");
    }

    return res;
  }, [errors]);

  const removeCommentHandler = (index: number) => {
    const comments = [...values.comments];
    comments.splice(index, 1)
    return setFieldValue('comments', comments);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <div className={styles.titleContainer}>
          <p>
            Добавляйте неограниченное количество комментариев и ответов к ним
          </p>
        </div>
        <div className={styles.topContainer}>
          <h4 className={styles.title}>Комментарий</h4>
          <button
            type="button"
            className={styles.generateCommentBtn}
            onClick={() => setShowCommentModal(true)}
          >
            Сгенерировать
          </button>
        </div>
        {values.comments.map((_, index) => (
          <div key={`comments_${index}`}>
            <Comment
              commentId={index}
              removeHandler={() => removeCommentHandler(index)}
            />
          </div>
        ))}
        <div className={styles.addBtnContainer}>
          <button
            className={styles.addCommentBtn}
            type="button"
            onClick={() => addCommentHandler()}>
            <>Добавить комментарий</>
            <img src={addIcon} alt="add icon"/>
          </button>
          <span className={styles.btn}>
            <PagesNavigation nexButtonDisabled={errorsSummary.length !== 0 && !!errors.comments}/>
        </span>
        </div>
      </div>
      <div className={styles.preview}>
        <PagePreview contentRef={contentRef}/>
      </div>
      {showCommentModal && <GenerateCommentModal hideModal={() => setShowCommentModal(false)}/>}
    </div>
  );
};
