import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import AccordionMUI from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { theme } from "../../global/theme";

const StyledAccordion = styled(AccordionMUI)`
  background-color: ${theme.greyBackground} !important;
  border-radius: 8px;
  margin-bottom: 32px;

  &.MuiPaper-root.MuiAccordion-root {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 32px;
    
    &:before {
      display: none;
    }
  }
`;

// title
const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiButtonBase-root {
    color: ${theme.blackButton};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    border-radius: 8px;
    background-color: ${theme.greyDark};
  }
  
  &.MuiButtonBase-root.Mui-expanded {
    min-height: 48px;
    .MuiAccordionSummary-content{
      margin: 0;
    }
  }
`;

// content
const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: #ececf4;
  padding-top: 32px;
  box-shadow: none;

  padding-bottom: 23px;
  border-bottom: 2px solid ${theme.greyDark};
`;

type Props = {
  children: React.ReactNode,
  title?: string,
  id?: string,
  controls?: string,
  defaultExpanded?: boolean,
}

export const Accordion: React.FC<Props> = ({
  children,
  title,
  id,
  controls,
  defaultExpanded = false
}) => {
  return (
    <StyledAccordion
      sx={{ '&.Mui-expanded': { mt: 0 } }}
      defaultExpanded={defaultExpanded}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={controls}
        id={id}
      >
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
