export const JWT_TOKEN = 'jwt.access.token';
export const JWT_REFRESH_TOKEN = 'jwt.refresh.token';

export const params = {
  ordering: 'ordering',
  limit: 'limit',
  page: 'page',
  domain: 'domain',
  country: 'country',
  pwa: 'pwa',
  date_after: "date_after",
  date_before: "date_before",
  search: "search",
  status: "status",
  vertical: "vertical",
  purpose: "purpose",
}
export const statisticsStatus = {
  install: 'install',
  lead: 'lead',
  sale: 'sale',
  open: 'open',
}
export const ordering = {
  country: 'country__name',
  pwa: 'pwa__name',
  domain: 'domain',
  click_id: 'click_id',
  is_install: 'is_install',
  is_lead: 'is_lead',
  is_sale: 'is_sale',
  updated_at: 'updated_at',
  created_at: 'created_at',
  clicks: 'clicks',
  installs: 'installs',
  leads: 'leads',
  amount: 'amount',
  sales: 'sales',
  status: 'status',
  is_open: "is_open",
}

export const balanceOrdering = {
  user: "user__username",
  amount: "amount"
}

export const ERROR = {
  INVALID_PAGE_ERROR: 'Invalid page.',
}