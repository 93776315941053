import styles from '../TariffsTable/TariffsTable.module.scss';
import React from "react";
import { TariffEntry } from "../../../types/tariffs";

type Props = {
  region: TariffEntry[],
  title: string,
}


export const TariffsTable: React.FC<Props> = ({ region, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.tableBodyContainer}>
        <div className={styles.tableBody}>
          {
            region.map((tariff: TariffEntry) => (
              <div key={tariff.country} className={styles.ratesRow}>
                <div>{tariff.country}</div>
                <div>{tariff.rate}</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )

}
