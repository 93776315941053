import { authorizedRequests } from "./auth";
import { BASE_URL } from "./constants";
import { LocalizationData } from "../types/localization";
import { AIComments, AICommentsBody, Application } from "../types/api";

export async function getLanguages() {
  const response = await authorizedRequests.get(
    BASE_URL + "/pwa/translate-template/"
  );
  return response.data as LocalizationData[];
}

export async function getTranslation(id: string) {
  const response = await authorizedRequests.get(
    BASE_URL + `/pwa/translate-template/${id}/`
  );
  return response.data as Application;
}

export async function getAllDescriptionsApi() {
  const response = await authorizedRequests.get(
    BASE_URL + "/pwa/description-library/"
  );
  return response.data as { id: number, description: string }[];
}

export async function getDescriptionsByIdApi(id: number) {
  const response = await authorizedRequests.get(
    BASE_URL + `/pwa/description-library/${id}/`
  );
  return response.data as { id: number, description: string };
}

export async function getDescriptionTranslation(id: number, language_id: number) {
  const response = await authorizedRequests.post(
    BASE_URL + `/pwa/description-library/${id}/translate_description/`,
    { language_id: language_id }
  );
  return response.data as { success: boolean, translated_description: string };
}

export async function getGeneratedComments(data: AICommentsBody) {
  const response = await authorizedRequests.post(
    BASE_URL + "/pwa/comments/generate_comments/",
    data
  );
  return response.data as AIComments;
}






