import React, { useEffect } from "react";

import styles from './TariffsPage.module.scss';
import { TariffsTable } from "../../components/Tables/TariffsTable/TariffsTable";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { geTariffsData, getTariffsData } from "../../store/reducers/tariffsReducer";


export const TariffsPage = () => {
  const dispatch = useAppDispatch();

  const tariffs = useAppSelector(geTariffsData);


  useEffect(() => {
    dispatch(getTariffsData());
  }, [dispatch]);

  return (
    <>
      <div className="title-container">
        <h2>Тарифы</h2>
      </div>
      <div className="subtitle">Тарификация за один инсталл PWA</div>

      <div className={styles.tablesContainer}>
        <TariffsTable region={tariffs["tariffs.tier.one"]} title="Регион 1"/>
        <TariffsTable region={tariffs["tariffs.tier.two"]} title="Регион 2"/>
        <TariffsTable region={tariffs["tariffs.tier.three"]} title="Регион 3"/>
      </div>
    </>
  )

}