import React, {useRef} from "react";
import {useCloseModal} from "../hooks";
import {Loader} from "../../Loader/Loader";
import styles from "./PendingPWAModal.module.scss";


type PendingPWAModalProps = {
    active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PendingPWAModal: React.FC<PendingPWAModalProps> = ({active, setActive}) => {

    const modalRef = useRef<HTMLDivElement>(null);

    useCloseModal(modalRef, () => setActive(false));

    return (
        <div className="modal-background">
            <div className={styles.wrapper} ref={modalRef}>
                <div className="modal-titleContainer">
                    <div className="modal-title">Создание PWA</div>
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.warningInfo}>
                        Создание займет некоторое время. Не обновляйте и не закрывайте страницу.
                    </div>
                </div>
                <div className="modal-bottomWarapper">
                    <Loader/>
                </div>
            </div>
        </div>
    )
}