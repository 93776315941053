import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserData } from "../../api/auth";
import { RootState } from "../store";


export enum AuthStatus {
  Guest,
  LoggedIn,
  Error,
}

const initialState = {
  username: "",
  id: -1,
  email: "",
  authStatus: AuthStatus.Guest,
  is_staff: false,
  is_partner: false,
};

export const getUserDataThunk = createAsyncThunk(
  'userReducer/getUserData',
  async () => {
    return await getUserData();
  },
);

const userReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    resetAuthStatus: (state) => {
      state.authStatus = AuthStatus.Guest;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDataThunk.fulfilled, (state, action) => {
      state.username = action.payload.username;
      state.id = action.payload.id;
      state.authStatus = AuthStatus.LoggedIn;
      state.is_staff= action.payload.is_staff;
      state.is_partner=action.payload.is_partner_user;
    });
    builder.addCase(getUserDataThunk.rejected, (state) => {
      state.authStatus = AuthStatus.Error;
    });
  }
});

export const getUserName = (state: RootState) => state.userReducer.username;

export const getUserId = (state: RootState) => state.userReducer.id;
export const getAuthStatus = (state: RootState) => state.userReducer.authStatus;

export const getUserStatus = (state: RootState) => state.userReducer.is_staff;
export const getUserPartners = (state: RootState)=> state.userReducer.is_partner;

export const { resetAuthStatus } = userReducer.actions;
export default userReducer.reducer;
