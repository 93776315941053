import React from "react";

import { params } from "../../api/helpers/constants";
import { paginationRows } from "../../helpers/lists";
import { useSearchParams } from "react-router-dom";
import { Select } from "./Select";

export const ParamsRowSelect = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Select
      value={searchParams.get(params.limit)}
      defaultValue={{ label: '10', value: '10' }}
      options={paginationRows.map(option => ({
        label: option.toString(),
        value: option.toString(),
      }))}
      theme="grey"
      icon="menu"
      onSet={(value: string) => setSearchParams(_params => {
        _params.set(params.limit, value);
        _params.set(params.page, '1');
        return _params;
      })}
    />
  );
};
