import React from "react";
import { useField, Field } from "formik";
import styles from "./FormInput.module.scss";
import { StyledInput } from "./FormInput.styled";

type Props = {
  name: string;
  placeholder?: string;
  defaultValue?: string | number;
  type?: string;
  min?: string | number,
  max?: string | number,
  step?: string | number,
};

export const FormInput: React.FC<Props> = ({
  name,
  placeholder,
  defaultValue,
  type,
  min,
  max,
  step,
}) => {
  const [, meta,] = useField(name);

  return (
    <>
      <Field
        name={name}
        as={StyledInput}
        fullWidth
        placeholder={placeholder}
        defaultValue={defaultValue}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        $isvalid={!(meta.touched && meta.error)}
        type={type}
        inputProps={{ min, max, step }}
      />
      {meta.touched && meta.error && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </>
  );
};

