export enum PaymentErrorStatus {
  already_paid = "already_paid",
  no_domains_available = "no_domains_available",
  not_enough_balance = "not_enough_balance",
  service_connection_error = "service_connection_error",
}

export type PaymentMessage = (
  { status: "success" } | { status: "invalid", error_status: PaymentErrorStatus, error_message: string, }
  ) & {
  data: {
    name: string,
    id: number,
    status: string,
    domain: string
  }
}