import React from "react";
import styles from "./Button.module.scss";

type Props = {
  appear?: "basic" | "outline" | "outlineWithBorder";
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  height?: string;
  width?: string;
  type?: "button" | "submit" | "reset";
};

export const Button: React.FC<Props> = ({
  appear = "basic",
  onClick,
  children,
  disabled,
  height,
  width,
  type,
}) => {
  return (
    <button
      style={{
        height: height ? height : '',
        width: width ? width : '',
      }}
      onClick={onClick}
      disabled={disabled}
      className={`${styles.button} ${appear === "outline" ? styles.outline : ""} ${appear === "outlineWithBorder" ? styles.outlineWithBorder : ""}`}
      type={type || "button"}
    >
      {children}
    </button>
  );
};
