import React from "react";
import { Button } from "../../components/Button/Button";
import { Search } from "../../components/Search/Search";
import { useDebounce } from "../../hooks/useDebounce";
import styles from "../../../src/components/Tables/Table.module.scss";
import { PixelApiTable } from "../../components/Tables/PixelApiTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { params } from "../../api/helpers/constants";
import { ParamsRowSelect } from "../../components/Select/ParamsRowSelect";


export const PixelAPI = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useDebounce(
    (value) =>
      setSearchParams(_params => {
        if (value) {
          _params.set(params.search, value);
        } else {
          _params.delete(params.search);
        }
        return _params;
      }),
    searchParams.get(params.search) || "",
    1000,
  );


  return (
    <>
      <div className={styles.wrapper}>
        <div className="title-container">
          <h2>Pixel API</h2>
          <Button onClick={() => navigate("/create-pixel")}>Добавить новый</Button>
        </div>
        <div className="filters-panel">
          <div><ParamsRowSelect/></div>
          <Search
            value={search}
            onChange={(newValue: string) => setSearch(newValue)}
          />
        </div>
        <PixelApiTable/>
      </div>
    </>
  );
};
