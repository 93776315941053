import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import Modal from "@mui/material/Modal/Modal";
import { UploadModal } from "../../../components/UploadModal/UploadModal";
import styles from "./Design.module.scss";
import uploadIcon from "../../../img/uploadIcon.svg";
import { PagePreview } from "../../../components/PagePreview/PagePreview";
import FormSelect from "../../../components/FormSelect";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  addVerticalTemplate,
  deleteVerticalTemplate, getPreviewTemplateById,
  getVerticalTemplates, TEMPLATES_PAGE_SIZE, updateVerticalTemplates
} from "../../../store/reducers/pwaFormReducer";
import {
  getPwaEnumsVerticalPreviewTemplate, getPwaEnumsVerticalTemplate
} from "../../../store/reducers/globalDataReducer";
import { PwaFormValues } from "../helpers/formikHelpers";
import deleteIcon from "../../../img/deleteTemplateIcon.svg";
import { getUserId } from "../../../store/reducers/userReducer";
import { PagesNavigation } from "../PagesNavigation/PagesNavigation";
import { Pagination } from "@mui/material";
import { theme } from "../../../global/theme";

export const Design = () => {
  const { values, setFieldValue } = useFormikContext<PwaFormValues>();
  const dispatch = useAppDispatch();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const verticalTemplates = useAppSelector(getVerticalTemplates);

  const userId = useAppSelector(getUserId);
  const verticalPreviewTemplatesEnum = useAppSelector(getPwaEnumsVerticalPreviewTemplate);
  const verticalTemplateEnum = useAppSelector(getPwaEnumsVerticalTemplate);

  useEffect(() => {
    dispatch(updateVerticalTemplates(1));
  }, []);

  async function handleSelectTemplate(id: number) {
    if (id !== values.vertical?.preview_template) {
      await setFieldValue("vertical.preview_template", id);
      dispatch(getPreviewTemplateById(id));
    }
  }

  async function handleDeleteTemplate(id: number) {
    await dispatch(deleteVerticalTemplate(id));

    if (id === values.vertical?.preview_template) {
      const newSelected = verticalTemplates.data[0].id === id
        ? verticalTemplates.data[1].id
        : verticalTemplates.data[0].id;

      await handleSelectTemplate(newSelected);
    }
  }

  useEffect(() => {
    if (verticalTemplates.count !== 0 && values.vertical?.preview_template === undefined) {
      handleSelectTemplate(verticalTemplates.data[0].id);
    }
  }, [verticalTemplates, setFieldValue]);


  const handleModalClose = () => {
    setModalOpen(false);
  };

  const saveImg = async (icon: string, imgArray: string[]) => {
    if (!values.vertical?.vertical) return;

    dispatch(addVerticalTemplate({
      template_type: values.vertical.vertical,
      icon,
      screenshot_1: imgArray[0],
      screenshot_2: imgArray[1],
      screenshot_3: imgArray[2],
      screenshot_4: imgArray[3],
      callback: handleSelectTemplate
    }));
    setModalOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <UploadModal handleClose={handleModalClose} handleSave={saveImg}/>
      </Modal>
      <div className={styles.sidesWrapper}>
        <div className={styles.leftSide}>
          <div className={styles.titleContainer}>
            <p className={styles.subTitle}>
              Выберите набор картинок для PWA или загрузите свои
            </p>
          </div>

          <div className={styles.downloadFilesContainer}>
            <button
              type="button"
              disabled={!values.vertical?.vertical}
              className={styles.uploadBtn}
              onClick={() => setModalOpen(true)}
            >
              <p> Загрузить шаблоны</p>
              <img width={24} height={24} src={uploadIcon} alt="upload Icon"/>
            </button>
          </div>

          <div className={styles.inputsContainer}>
            <span className={styles.inputItem_vertical}>
              <label>Вертикаль</label>
              <FormSelect
                name="vertical.vertical"
                options={verticalPreviewTemplatesEnum}
                placeholder="Выберите вертикаль"
              />
            </span>
            <span className={styles.inputItem}>
              <label>Темплейт</label>
              <FormSelect
                name="vertical.template"
                options={verticalTemplateEnum}
                placeholder="Выберите темплейт"
              />
            </span>
          </div>

          <div className={styles.templatesContainer}>
            <div className={styles.templatesContainer_title}>Шаблоны для PWA</div>
            {verticalTemplates.data
              .map(template => (
                <div
                  key={`vertical-template-${template.id}`}
                  onClick={() => handleSelectTemplate(template.id)}
                  className={`${styles.imagesContainer} ${
                    template.id === values.vertical?.preview_template ? styles.imagesContainerSelected : ""}`
                  }
                >
                  <div className={styles.iconContainer}>
                    {template.icon
                      && <img src={template.icon} alt="Icon" className={styles.iconPreview}/>}
                  </div>
                  {[
                    template.screenshot_1,
                    template.screenshot_2,
                    template.screenshot_3,
                    template.screenshot_4,
                  ].map((image, index) => <div
                    key={`vertical_preview_screenshot_${index + 1}`}
                    className={styles.imgContainer
                    }>
                    {image && (<img src={image} alt="preview" className={styles.imgPreview}/>)}
                  </div>)}
                  {template.owner === userId && <button
                      type="button"
                      className={styles.deleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTemplate(template.id);
                      }}
                  ><img src={deleteIcon} width={24} height={24} alt="delete icon"/></button>}
                </div>
              ))}
            <Pagination
              page={verticalTemplates.currentPage}
              count={Math.ceil(verticalTemplates.count / TEMPLATES_PAGE_SIZE)}
              shape="rounded"
              onChange={(_, page) => {
                dispatch(updateVerticalTemplates(page));
              }}
              sx={{
                '& .MuiPagination-ul > li:first-of-type': { marginRight: 'auto' },
                '& .MuiPagination-ul > li:last-of-type': { marginLeft: 'auto' },
                '& .MuiPaginationItem-root': {
                  borderRadius: "6px",
                  '&:hover': {
                    backgroundColor: theme.greyDark,

                  },
                  '&.Mui-selected': {
                    backgroundColor: theme.white,
                    color: theme.redPrimary,
                    '&:hover': {
                      border: "none",
                      backgroundColor: theme.greyDark,

                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={styles.preview}>
          <PagePreview/>
        </div>
      </div>
      <PagesNavigation nexButtonDisabled={!values.vertical?.vertical || !values.vertical?.template}/>
    </div>
  );
};
