import styles from "./UserHeader.module.scss";
import { UserPopUp } from "../UserPopUp/UserPopUp";
import { useAppSelector } from "../../store/hooks";
import { getUserName } from "../../store/reducers/userReducer";

export const UserHeader = () => {
  const username = useAppSelector(getUserName);

  return (
    <div className={styles.wrapper}>
      <div className={styles.pic}>
        <div className={styles.picInner}>
          {username.slice(0,2)}
        </div>
      </div>
      <div className="col-s">
      </div>
      <div className={styles.userPopUp}>
        <UserPopUp/>
      </div>
    </div>
  );
};
