import React, { useState } from 'react';
import { ErrorMessage, useField } from "formik";
import styles from '../../components/InputSmall/InputSmall.module.scss';
import eyeOpen from '../../img/eyeIcon.svg';
import eyeClose from '../../img/eyeClosed.svg';


type Props = {
  name: string,
  type?: string,
  label: string,
  placeholder?: string,
  onChange: (e: React.ChangeEvent<any>) => void,
  onBlur: (e: React.FocusEvent<any>) => void,
  value: string,
  submitWithEnter: boolean,
}

const InputSmall: React.FC<Props> = (
  {
    name,
    type = "text",
    label,
    placeholder,
    onChange,
    onBlur,
    value,
    submitWithEnter = false
  }
) => {
  const [, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <span className={styles.inputLabel}>{label}</span>
      <div className={styles.inputContainer}>
        <input
          type={type === "password" && !showPassword ? "password" : "text"}
          placeholder={placeholder}
          className={`${styles.customInput} ${(meta.touched && meta.error) && styles.errorInput}`}
          name={name}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !submitWithEnter) {
              e.preventDefault();
            }
          }}
          onBlur={onBlur}
          value={value}
          autoComplete="off"
        />
        {type === "password" && <img
            src={showPassword ? eyeClose : eyeOpen}
            className={styles.showBtn}
            onClick={() => setShowPassword(prev => !prev)}
            alt="eye icon"
        />}
        <div className="errorMessage--left"><ErrorMessage name={name}/></div>
      </div>
    </>
  );
}

export default InputSmall;