import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { theme } from "../../global/theme";

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    background-color: rgba(236, 236, 243, 0.4);
    border-radius: 16px 16px 0 0;
    width: fit-content;
  }
  
  .MuiTabs-indicator {
    display: none;
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: 400;
    font-family: "Ubuntu", sans-serif;
    color: $blackText;
    text-transform: capitalize;
    font-size: 16px;
   
  }
  
  &.MuiTab-root.Mui-disabled {
    font-weight: 400;
    color: ${theme.greyDark};
  }
  
  &.MuiTab-root.Mui-selected {
    border-radius: 16px 16px 0 0;
    background-color: ${theme.greyBackground};
    color: ${theme.blackButton};
    font-weight: 500;
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
  }
`;

export const StyledLoader = styled.div `
  position: relative;
  width: 100%;
  border-radius: 0 16px 16px 16px;
  background: ${theme.greyBackground};
  padding: 150px 20px;
`