import React from "react";
import styles from "./Search.module.scss"
import smallCloseRed from "../../img/smallCloseRed.svg";

type Props = {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
}
export const Search: React.FC<Props> = ({
  value, onChange, placeholder= "Поиск"
}) => {
  return (
    <div className={styles.search}>
      <input
        value={value}
        className={styles.input}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {value &&
        <img
          src={smallCloseRed}
          alt="close icon"
          className={styles.closeIcon}
          onClick={() => onChange('')}
        />}
    </div>
  )
}