import React from "react";
import styles from "../Documentation/Documentation.module.scss";
import clipboardIcon from "../../img/clipboard.svg";

export const Documentation = () => {

  const documentationData = [
    {
      id: 1,
      title: "FB Pixel",
      link: "https://stingy-trout-0f1.notion.site/PWA-FB-PIXEL-4c3144ab6ae14b57be55d21bf9fd4f80?pvs=4",
      icon: clipboardIcon
    },
    {
      id: 2,
      title: "Создание PWA",
      link: "https://stingy-trout-0f1.notion.site/PWA-PWA-9d89f471755c4f07a6c01637e508d918?pvs=4",
      icon: clipboardIcon
    },
    {
      id: 3,
      title: "Интеграция с трекером",
      link: "https://stingy-trout-0f1.notion.site/PWA-b6fd6ccca2c24284a9652374383d12e7?pvs=4",
      icon: clipboardIcon
    }
  ]

  return (
    <>
      <div className="title-container">
        <h2>Документация</h2>
      </div>

      <div className={styles.contentContainer}>
        {documentationData.map(doc =>
          <a
            key={doc.id}
            href={doc.link}
            target="_blank"
            className={styles.linkContainer}
          >
            <div className={styles.innerContainer}>
              <img src={doc.icon} alt="clipboard"/>
              {doc.title}
            </div>
          </a>)
        }
      </div>
    </>
  )
};