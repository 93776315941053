import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

import { StyledSelect } from './Select.styled';
import { SelectList } from "../../types/global";
import { useAppSelector } from "../../store/hooks";
import { getUserCloaksList } from "../../store/reducers/pwaFormReducer";

type Props = {
  values?: SelectList;
  onChange: (value: string[]) => void,
};


export const IpMultiInput = ({
  values,
  onChange,
}: Props) => {
  const userCloaksList = useAppSelector(getUserCloaksList);
  const [currentInput, setCurrentInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <StyledSelect $theme="normal" $isvalid $icon="none">
      <CreatableSelect
        value={values || []}
        options={userCloaksList}
        onInputChange={(value) => setCurrentInput(value)}
        onKeyDown={(e) => {
          setErrorMessage("");
          if (
            e.key === "Enter"
            && (values?.find(ip => ip.value === currentInput))
          ) {
            e.preventDefault();
          }
        }}
        onBlur={() => setErrorMessage("")}
        onChange={(ips) => {
          const ipList: string[] = [];
          for (const ip of ips) {
            if (
              (/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/).test(ip.value)
              || isIPv6(ip.value)
            ) {
              ipList.push(ip.value);
            } else {
              setErrorMessage("IP должен быть правильного формата: 0.0.0.0 (IPv4) или 0:0:0:0:0:0:0:0 (IPv6)");
            }
          }
          return onChange(ipList);
        }}
        isMulti
        classNamePrefix="styled-select"
        placeholder="Введите IP адреса"
      />
      {errorMessage && <div className="errorMessage">{errorMessage}</div>}
    </StyledSelect>
  );
};

function isIPv6(ip: string) {
  const groups = ip.split('::');

  let valuesSum = 0;
  for (const group of groups) {
    if (group.length === 0) continue;
    const values = group.split(':');

    for (const value of values) {
      if (value.length > 4 || !(/^[0-9A-Fa-f]+$/).test(value)) return false;
    }

    valuesSum += values.length;
  }

  return !(
    valuesSum > 8
    || (valuesSum !== 8 && !ip.includes('::'))
  );
}