import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getBalances,
  getCryptoWallet,
  getFinanceTransactions,
  getFinanceTransactionsOptions,
} from "../../api/finance";
import { Balances, Transaction, Users } from "../../types/finance";
import { RootState } from "../store";
import { getDefaultParams } from "../../api/helpers/scripts";
import { AxiosError } from "axios";
import { ErrorMessage } from "../../types/api";

type InitialState = {
  transactions: {
    data: Transaction[],
    count: number,
  },
  statusOptions: { [key: string]: string },
  cryptoWallet: string,
  balances: {
    data: Balances[],
    count: number,
  },
  users: Users[]
}

export const DEFAULT_ROWS_PER_PAGE = 10;

const initialState: InitialState = {
  transactions: {
    data: [],
    count: 0,
  },
  statusOptions: {},
  cryptoWallet: "",
  balances: {
    data: [],
    count: 0,
  },
  users: []
};


function getFinanceParams() {
  const params = new URLSearchParams(window.location.search);
  return getDefaultParams(params, DEFAULT_ROWS_PER_PAGE);
}

export const getTransactionsData = createAsyncThunk(
  'financeReducer/getTransactionsData',
  async () => {
    const [
      transactionsDataOptions,
    ] = await Promise.all([
      getFinanceTransactionsOptions(),
    ]);

    return {
      transactionsDataOptions,
    };
  },
);

export const getCryptoWalletThunk = createAsyncThunk(
  'financeReducer/getCryptoWalletThunk',
  async () => {
    return await getCryptoWallet();
  },
);

export const getTransactionsThunk = createAsyncThunk(
  'financeReducer/getTransactions',
  async (_,{ rejectWithValue, signal }) => {
    try {
      return await getFinanceTransactions(getFinanceParams(), signal);
    } catch (err: any){
      const error = err as AxiosError<ErrorMessage>;
      if (!error.response) {
        throw error
      }

      throw rejectWithValue(error.response!.data);
    }

  },
);

export const getBalancesThunk = createAsyncThunk(
  'financeReducer/getBalances',
  async (_,{ rejectWithValue, signal }) => {
    try {
      return await getBalances(getFinanceParams(), signal);
    }catch (err: any) {
      const error = err as AxiosError<ErrorMessage>;
      if (!error.response) {
        throw error
      }
      throw rejectWithValue(error.response!.data);
    }
  },
);

export const geUsersThunk = createAsyncThunk(
  'financeReducer/getUsers',
  async () => {
    return await getAllUsers();
  },
);


const financeReducer = createSlice({
  name: "financeReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionsData.fulfilled, (state, action) => {
      state.statusOptions = action.payload.transactionsDataOptions.status_choices;
    });

    builder.addCase(getCryptoWalletThunk.fulfilled, (state, action) => {
      state.cryptoWallet = action.payload.crypto_currency_wallet;
    });

    builder.addCase(getTransactionsThunk.fulfilled, (state, action) => {
      state.transactions.data = action.payload.results;
      state.transactions.count = action.payload.count;
    });

    builder.addCase(getBalancesThunk.fulfilled, (state, action) => {
      state.balances.data = action.payload.results;
      state.balances.count = action.payload.count;
    });

    builder.addCase(geUsersThunk.fulfilled, (state, action) => {
      state.users= action.payload;
    });
  }
});


export const getTransactions = (state: RootState) => state.financeReducer.transactions;
export const getStatusOptions = (state: RootState) => state.financeReducer.statusOptions;
export const getUserCryptoWallet = (state: RootState) => state.financeReducer.cryptoWallet;

export const getBalancesData = (state: RootState) => state.financeReducer.balances;

export const getAllUsersData = (state: RootState)=> state.financeReducer.users;


export default financeReducer.reducer;