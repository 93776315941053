let BASE_URL: string;

switch (process.env.REACT_APP_MODE) {
  case 'production':
    BASE_URL = 'https://pwa.wildwildapps.com/api';
    break;

  case 'stage':
    BASE_URL = 'https://pwawwtes.site/api';
    break;

  default:
    BASE_URL = 'https://pwawwtes.site/api';
    break;
}

export { BASE_URL };