import styles from "./WalletBalance.module.scss";
import walletIcon from "../../img/walletIcon.svg";
import {useAppSelector } from "../../store/hooks";
import { getWalletBalanceAmount } from "../../store/reducers/globalDataReducer";
import React, {useState } from "react";
import { BalanceRefill } from "../Modals/BalanceRefill/BalanceRefill";
import CountUp from "react-countup";
import { getUserPartners} from "../../store/reducers/userReducer";


export const WalletBalance = () => {
  const balance = useAppSelector(getWalletBalanceAmount) || 0;
  const [showModal, setShowModal] = useState(false);
  const userPartner = useAppSelector(getUserPartners);

    return (
      <>
        {!userPartner && (
          <div className={styles.wrapper}>
            <img
              src={walletIcon}
              width={32}
              height={32}
              alt="wallet icon"
              className={styles.walletIcon}
              onClick={() => {
                setShowModal(true);
              }}
            />
            <div className="col-s">
              <CountUp
                start={0}
                end={Number(balance)}
                duration={2}
                separator=","
                decimals={2}
                decimal="."
                prefix="$ "
                className={styles.balanceVal}
              />
            </div>
            {showModal && <BalanceRefill hideModal={() => setShowModal(false)} />}
          </div>
        )}
      </>
    )
}