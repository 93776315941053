import { RefObject, useEffect } from 'react';

export const useCloseModal = (modalRef: RefObject<HTMLDivElement>, hideModal: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event:MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        hideModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [modalRef, hideModal]);
};