import {
  LocalizationProvider,
  PickersLayout,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styled from "styled-components";
import { Dayjs } from "dayjs";
import { theme } from "../../global/theme";



type Props = {
  value?: Dayjs | null;
  defaultValue?: Dayjs;
  onChange: (date: Dayjs) => void;
  label?: string,
  className?: string,
  name?: string,
}

const StyledDatePicker = styled(DesktopDatePicker)`
  .MuiInputBase-root {
    height: 50px !important;
    padding: 8px 8px !important;
    background: #fff;
    border-radius: 8px;
    font-family: Ubuntu, sans-serif !important;
  }
  
  .MuiInputLabel-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
  }
  
  .MuiIconButton-root {
    margin-right: 0;
  }

  .MuiInputLabel-root  {

    &.Mui-focused {
      color: grey;
    }
  
  }
`;
const StyledDatePickersLayout = styled(PickersLayout)`
  .MuiButtonBase-root.MuiPickersDay-root {
    &.Mui-selected {
      background-color: ${theme.redPrimary};
    }
  }
`;

export const DatePicker = ({ defaultValue, onChange, value, label, className, name}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        name={name}
        className={className || ""}
        label={label}
        value={value || null}
        onChange={(e) => onChange(e as Dayjs)}
        defaultValue={defaultValue}
        slots={{ layout: StyledDatePickersLayout }}
        format="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};
