export const theme = {
  black: "#2E2B2B",
  blackButton: "#4D4D5E",
  greyPrimary: "#939CB4",
  greyBackground: "#ECECF3",
  redPrimary: "#D11A2B",
  white: "#FBFBFB",
  redGradient: "#D11A2B, #FFFFFF",
  lightGrey: "#D8DCE0",
  greyDark: "#CBCBE0",
  red20: "#A71522",
  selectedBlue: "#1976d2",
  yellow: "#FFCB3D",
}