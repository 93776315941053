import React from 'react';
import styles from '../AuthLayout/AuthLayout.module.scss';
import { Link } from "react-router-dom";

type Props = {
  title: string,
  subtitle: string,
  showSignUpLink?: boolean,
  children?: React.ReactNode
}
const AuthLayout: React.FC<Props> = ({
  title,
  subtitle,
  showSignUpLink = false,
  children
}) => {
  return (
    <div className={styles.auth}>
      <div className={styles.mainBlock}>
        <div>
          <h1 className={styles.title}>{title}</h1>
          <h2 className={styles.subtitle}>{subtitle}</h2>
        </div>
        <div className={styles.content}>
          {children}
        </div>
        {showSignUpLink ? (<div className={styles.linkContainer}>
          Don't have an account? <Link to={'/signup'}>Sign up</Link>
        </div>) : (<div className={styles.linkContainer}>
          Have an account? <Link to={'/login'}>Login</Link>
        </div>)}
      </div>
      <div className={styles.rights}><span className={styles.rightsText}>Copyright © {new Date().getFullYear()} Wild Wild Leads</span></div>
    </div>
  );
}

export default AuthLayout;