import React, { useRef } from "react";
import styles from './InvoiceForPayment.module.scss';
import closeIcon from "../../../img/closeIcon.svg";
import paidCheckmark from "../../../img/billsIcon/paidCheckmark.svg";
import inProcess from "../../../img/billsIcon/inProcess.svg";
import notValid from "../.././../img/billsIcon/notValid.svg";
import { Button } from "../../Button/Button";
import { useCloseModal } from "../hooks";

type Props = {
  hideModal: () => void;
  amount: string,
  transactionId: number,
  status: string;
};


export const InvoiceForPaymentModal: React.FC<Props> = ({ hideModal, amount, transactionId, status }) => {

  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Счет на оплату</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        <div className={styles.invoiceID}>ID&nbsp; #{transactionId}</div>
        <div className={styles.invoiceAmount}>Сумма&nbsp; &nbsp;   ${amount}</div>
        <div className={styles.paidContainer}>
          {status === "pending" &&
              <>
                  <img src={inProcess} alt="in process icon"/>
                  <div>В процесе</div>
              </>
          }
          {status === "success" &&
              <>
                  <img src={paidCheckmark} alt="success icon"/>
                  <div>Успешно оплачен</div>
              </>
          }
          {status === "invalid" &&
              <>
                  <img src={notValid} alt="not valid icon"/>
                  <div>Недействительная</div>
              </>
          }
        </div>

        <div className="modal-bottomWarapper">
          <Button onClick={hideModal}>Закрыть</Button>
        </div>

      </div>
    </div>
  )
}