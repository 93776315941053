import React, { useEffect, useState } from "react";

import styles from "./Table.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PixelSortColumn } from "../../pages/StatisticsPages/helpers";
import { DEFAULT_ROWS_PER_PAGE, getPixelListThunk, getUserPixelList } from "../../store/reducers/pixelReducer";
import penIcon from "../../img/tabletools/penIcon.svg";
import deleteIcon from "../../img/tabletools/trashBinIcon.svg";
import { Link, useSearchParams } from "react-router-dom";
import { DeletePixelModal } from "../Modals/DeletePixelModal/DeletePixelModal";
import { ParamsSortIcon } from "../SortIcon/ParamsSortIcon";
import { ParamsPagination } from "../Pagination/ParamsPagination";
import { ERROR, params } from "../../api/helpers/constants";

export const PixelApiTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const pixelList = useAppSelector(getUserPixelList);
  const [deleteModal, setDeleteModal] = useState(-1);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const promise = dispatch(getPixelListThunk());

    promise.unwrap().catch(error => {
      if (error.detail === ERROR.INVALID_PAGE_ERROR) {
        setSearchParams(_params => {
          _params.set(params.page, '1');
          return _params;
        });
      }
    });

    return () => {
      promise.abort();
    }
  }, [dispatch, searchParams]);

  return (
    <>
      <table className={`${styles.table} ${styles.pixelTable}`}>
        <thead>
        <tr>
          {([
              { type: "pixel_id", title: "Pixel" },
              { type: "token", title: "Token" },
              { type: "actions", title: "Действия" },
            ] as { type: PixelSortColumn, title: string }[]
          ).map((column, index) => (
            <th key={column.type}>{column.title}
              {index < 2 && <ParamsSortIcon sortsBy={column.type}/>}
            </th>
          ))}
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {pixelList.data.length > 0 ? pixelList.data.map((row, index) => (
          <tr key={index + "trow"}>
            <td>{row.pixel_id}</td>
            <td>
              <div className={styles.ellipsis}>
              {row.token}
              </div>
            </td>
            <td className={styles.iconsContainer}>
              <Link to={`/pixel-api/edit/${row.id}`}>
                <img src={penIcon} width={24} height={24} alt="edit Icon"/>
              </Link>
              <button
                type="button"
                onClick={() => {
                  setDeleteModal(row.id);
                }}
              >
                <img src={deleteIcon} width={24} height={24} alt="delete Icon"/>
              </button>
              {deleteModal === row.id && <DeletePixelModal
                  id={row.id}
                  hideModal={() => setDeleteModal(-1)}
              />}
            </td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
      </table>
      <ParamsPagination
        listLength={pixelList.count}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
      />
    </>
  );
};
