import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import useWebSocket from 'react-use-websocket';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { Checkbox } from "../Checkbox/Checkbox";
import { TableTools } from "../TableTools/TableTools";
import styles from "./PwaTable.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPwaEnumsStatus } from "../../store/reducers/globalDataReducer";
import {
  changePwaDomainAndStatusAction,
  deselectPWA,
  getCheckedPWAList, getCreatedPWAList,
  selectAllPWAs,
  selectNoPWA,
  selectPWA,
} from "../../store/reducers/pwaListReducer";
import { Status } from "../../types/api";
import { decodePaymentEvent, getPaymentWebSocket, wsOptions } from "../../api/webSocket";


export const PwaTable = () => {


  const dispatch = useAppDispatch();
  const statusesList = useAppSelector(getPwaEnumsStatus);
  const checkedPWAs = useAppSelector(getCheckedPWAList);
  const pwaList = useAppSelector(getCreatedPWAList);

  const { lastMessage } = useWebSocket(getPaymentWebSocket, wsOptions);



  useEffect(() => {
    if (!lastMessage) return;

    const paymentData = decodePaymentEvent(lastMessage);

    dispatch(changePwaDomainAndStatusAction({
      pwa_id: paymentData.data.id,
      status: paymentData.data.status,
      domain: paymentData.data.domain
    }));

    if (paymentData.status === "success") {
      enqueueSnackbar(`PWA "${paymentData.data.name}" успешно оплачен`,
        { variant: 'success', style: { backgroundColor: '#01875F' } });
    } else {
      enqueueSnackbar(`При оплате PWA "${paymentData.data.name}" произошла ошибка: ${paymentData.error_message}`,
        { variant: 'error', style: { backgroundColor: '#D11A2B' } });
    }

  }, [lastMessage]);

  const statusToLabel = useMemo(() => {
    const res: { [key: string]: string } = {};
    for (const status of statusesList) {
      res[status.value] = status.label;
    }

    return res;
  }, [statusesList]);

  return (
    <>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
      <table className={styles.table}>
        <thead>
        <tr>
          <th>
            <Checkbox
              checked={pwaList.data.length === checkedPWAs.size && pwaList.data.length !== 0}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(selectAllPWAs());
                } else {
                  dispatch(selectNoPWA());
                }
              }}
            />
          </th>
          <th className={styles.nameV}>PWA</th>
          <th>Статус</th>
          <th>Домен</th>
          <th>Вертикаль</th>
          <th>Гео</th>
          <th></th>
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {pwaList.data.length > 0 ? pwaList.data.map((item, index) => (
          <tr key={index + "trow"}>
            <td>
              <Checkbox
                checked={checkedPWAs.has(item.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(selectPWA(item.id));
                  } else {
                    dispatch(deselectPWA(item.id));
                  }
                }}
              />
            </td>
            <td className={styles.nameV}> {
              item.icon && <img src={item.icon} alt="pwa icon" className={styles.icon}/>
            }<Link to={`/edit/${item.id}`} className={styles.link}>{item.name}</Link></td>
            <td className={item.status === Status.in_process ? styles.status : styles.statusV}>{
              item.status
                ? statusToLabel[item.status]
                : ""
            }</td>
            <td className={item.domain && styles.domain}>
              {item.domain ? <a
                href={`https://${item.domain}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.domain}
              </a> : '—'}
            </td>
            <td className={styles.statusV}>
              {item.vertical}
            </td>
            <td>
              <div className={styles.countriesListContainer}>
                {item.countries.length > 0 ?
                  item.countries.length > 4
                    ? <Tooltip title={item.countries && item.countries.join(', ')}>
                      <div className={styles.countriesList}>
                        {item.countries && item.countries.join(', ')}
                      </div>
                    </Tooltip>
                    : <div className={styles.countriesList}>
                      {item.countries && item.countries.join(', ')}
                    </div> : "Все страны"
                }
              </div>
            </td>
            <td>
              <TableTools pwaData={item}/>
            </td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
      </table>
    </>
  )
    ;
};
