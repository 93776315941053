import React, { useRef } from "react";
import styles from './ExportReportModal.module.scss';
import closeIcon from "../../../img/closeIcon.svg";
import axios from "axios";
import { Button } from "../../Button/Button";
import { useCloseModal } from "../hooks";

type Props = {
  hideModal: () => void;
  downloadLink: string;
  fileName: string;
};


export const ExportReportModal: React.FC<Props> = ({ hideModal, downloadLink, fileName }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  const handleDownload = async () => {
    try {
      const token = localStorage.getItem('jwt.access.token');
      const response = await axios({
        url: downloadLink,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.csv`);
      link.click();

      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
    }
    hideModal();
  };

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Генерация отчета</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        <div className={styles.reportInfo}>Отчет будет доступен по ссылке в формате CSV с кодировкой UTF-8</div>
        <div className={styles.startInfo}>Для начала генерации отчета нажмите кнопку "Скачать"</div>
        <div className="modal-bottomWarapper">
          <Button onClick={handleDownload}>Скачать</Button>
          <button type="button" onClick={hideModal} className="modal-closeBtn">Закрыть</button>
        </div>

      </div>
    </div>
  )
}