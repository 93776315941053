import { Tokens } from '../types/api';
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from "../api/helpers/constants";

export function getAccessToken() {
  return localStorage.getItem(JWT_TOKEN);
}
export function getRefreshToken() {
  return localStorage.getItem(JWT_REFRESH_TOKEN);
}

export function setTokens(tokens: Tokens) {
  localStorage.setItem(JWT_TOKEN, tokens.access);
  localStorage.setItem(JWT_REFRESH_TOKEN, tokens.refresh);
}

export function updateToken(accessToken: string) {
  localStorage.setItem(JWT_TOKEN, accessToken);
}

export function deleteToken() {
  localStorage.removeItem(JWT_TOKEN);
  localStorage.removeItem(JWT_REFRESH_TOKEN);
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload) as {
    token_type: string,
    exp: number,
    iat: number,
    jti: string,
    user_id: number,
  };
}
