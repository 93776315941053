import { ParamsSortIcon } from "../SortIcon/ParamsSortIcon";
import { balanceOrdering} from "../../api/helpers/constants";
import React from "react";
import styles from "./Table.module.scss";
import { DEFAULT_ROWS_PER_PAGE, getBalancesData } from "../../store/reducers/financeReducer";
import { useAppSelector } from "../../store/hooks";
import { ParamsPagination } from "../Pagination/ParamsPagination";

export const BalanceTable = () => {
  const balances = useAppSelector(getBalancesData);
  return (
    <>
      <table className={`${styles.table} ${styles.balanceTable}`}>
        <thead>
        <tr>
          <th>Имя пользователя <ParamsSortIcon sortsBy={balanceOrdering.user}/></th>
          <th>Баланс<ParamsSortIcon sortsBy={balanceOrdering.amount}/></th>
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {balances.data.length > 0 ? balances.data.map((tx, index) => (
          <tr key={index + "trow"}>
            <td>{tx.user}</td>
            <td>{tx.amount}</td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
      </table>
      <ParamsPagination
        listLength={balances.count}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
      />
    </>
  )
}