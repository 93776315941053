import styles from "./BalancePage.module.scss"
import React, { useEffect, useState } from "react";
import filterIcon from "../../img/filterIcon.svg";
import { ParamsRowSelect } from "../../components/Select/ParamsRowSelect";
import { Search } from "../../components/Search/Search";
import { useDebounce } from "../../hooks/useDebounce";
import { ERROR, params } from "../../api/helpers/constants";
import { useSearchParams } from "react-router-dom";
import { BalanceTable } from "../../components/Tables/BalanceTable";
import { Button } from "../../components/Button/Button";
import { Select } from "../../components/Select/Select";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAllUsersData, getBalancesThunk, geUsersThunk } from "../../store/reducers/financeReducer";
import { ExportReportModal } from "../../components/Modals/ExportReportModal/ExportReportModal";
import { getExportBalancesLink } from "../../api/finance";
import { getUserStatus } from "../../store/reducers/userReducer";
import { ResetFiltersButton } from "../../components/ResetFiltersButton/ResetFiltersButton";

export const BalancePage = () => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(getAllUsersData);
  const adminUser = useAppSelector(getUserStatus);


  const usersOptions = users.map(user => ({ value: user.username, label: user.username }));

  const [searchParams, setSearchParams] = useSearchParams();


  const [name, setName] = useDebounce((value) =>
      setSearchParams(_params => {
        if (value) {
          _params.set(params.search, value);
        } else {
          _params.delete(params.search);
        }
        return _params;
      }),
    searchParams.get(params.search) || "",
    1000,
  );

  useEffect(() => {
    const promise =  dispatch(getBalancesThunk());

    promise.unwrap().catch(error => {
      if (error.detail === ERROR.INVALID_PAGE_ERROR) {
        setSearchParams(_params => {
          _params.set(params.page, '1');
          return _params;
        });
      }
    });
    dispatch(geUsersThunk());

    return () => {
      promise.abort();
    }

  }, [dispatch, searchParams]);

  const [showExportBalanceModal, setShowExportBalanceModal] = useState(false);

  function handleResetFilters() {
    setSearchParams(new URLSearchParams());
    setName("");

  }


  return (
    <div className={styles.wrapper}>
      {adminUser && <>
        <div className="title-container">
          <h2>Балансы</h2>
          <Button
            appear="outlineWithBorder"
            onClick={() => {
              setShowExportBalanceModal(true)
            }}
          >
            Экспорт балансов
          </Button>
        </div>

        <div className={`filters-panel ${styles.filtersBlock}`}>
          <div className={styles.selectContainer}>
            <img src={filterIcon} width={28} height={28} alt="filter icon"/>
            <div className={styles.usersSelect_wrapper}>
              <div className={styles.usersSelect}>
                <Select
                  value={searchParams.get(params.search)}
                  placeholder="Пользователь "
                  options={usersOptions}
                  onSet={(value) => {
                    setSearchParams(_params => {
                      _params.set(params.search, value);
                      _params.set(params.page, '1');
                      return _params;
                    });
                  }}
                />
              </div>
              <div>
                {(searchParams.get(params.search)
                ) && <ResetFiltersButton onClick={handleResetFilters}/>}
              </div>
            </div>

          </div>
          <div>
            <Search value={name} placeholder="Поиcк" onChange={(newValue) => setName(newValue)}/>
          </div>


        </div>
        <div className={styles.rowsNumberContainer}><ParamsRowSelect/></div>
        {showExportBalanceModal && <ExportReportModal
          downloadLink={getExportBalancesLink(searchParams)}
          hideModal={() => setShowExportBalanceModal(false)}
          fileName="balances"
        />}
      </>}
      {adminUser ? <BalanceTable/> : <div>Страница доступна только админу</div>}
    </div>
  )
}