import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { PreLanding, VerticalTemplate } from "../../types/api";

import {
  getPrelandings,
  getVerticalTemplates as getVerticalTemplatesApi,
  deleteVerticalTemplate as deleteVerticalTemplateApi,
  postVerticalTemplate, getUserWhiteIps,
  getPreviewTemplateById as getPreviewTemplateByIdApi,
} from "../../api/pwa";
import { SelectList } from "../../types/global";

type InitialState = {
  name: string,
  verticalTemplates: {
    data: VerticalTemplate[],
    count: number,
    currentPage: number,
  },
  selectedPreviewTemplate: VerticalTemplate | null,
  prelandingsOptions: PreLanding[],
  navigation: {
    currentPage: number,
    maxUnlockedPage: number,
  },
  userCloaks: SelectList,
}

const initialState: InitialState = {
  name: "",
  verticalTemplates: {
    data: [],
    count: 0,
    currentPage: 1,
  },
  selectedPreviewTemplate: null,
  prelandingsOptions: [],
  navigation: {
    currentPage: 0,
    maxUnlockedPage: 0,
  },
  userCloaks: [],
};

export const TEMPLATES_PAGE_SIZE = 3;

export const getPwaFormData = createAsyncThunk(
  'pwaFormReducer/getPwaFormData',
  async () => {
    const [
      verticalTemplatesData,
      prelandingsData,
      userWhiteIps,
    ] = await Promise.all([
      getVerticalTemplatesApi(new URLSearchParams({
        limit: TEMPLATES_PAGE_SIZE.toString(),
        page: '1'
      })),
      getPrelandings(),
      getUserWhiteIps(),
    ]);

    return {
      verticalTemplatesData,
      prelandingsData,
      userWhiteIps,
    };
  },
);


export const getPreviewTemplateById = createAsyncThunk<VerticalTemplate, number>(
  'pwaFormReducer/getPreviewTemplateById',
  async (id) => {
    return await getPreviewTemplateByIdApi(id);
  },
);


export const addVerticalTemplate = createAsyncThunk<void, {
  template_type: string,
  icon: string,
  screenshot_1: string,
  screenshot_2: string,
  screenshot_3: string,
  screenshot_4: string,
  callback: (id: number) => void,
}>(
  'pwaFormReducer/addVerticalTemplate',
  async (data, { dispatch }) => {
    const {
      template_type,
      icon,
      screenshot_1,
      screenshot_2,
      screenshot_3,
      screenshot_4
    } = data;
    const { id } = await postVerticalTemplate(
      {
        template_type,
        icon,
        screenshot_1,
        screenshot_2,
        screenshot_3,
        screenshot_4
      }
    );
    await dispatch(updateVerticalTemplates(1));
    data.callback(id);
  },
);

export const deleteVerticalTemplate = createAsyncThunk<void, number>(
  'pwaFormReducer/addVerticalTemplate',
  async (id, { dispatch }) => {
    await deleteVerticalTemplateApi(id);
    await dispatch(updateVerticalTemplates(1));
  },
);

export const updateVerticalTemplates = createAsyncThunk(
  'pwaFormReducer/updateVerticalTemplates',
  async (page: number, { dispatch }) => {
    dispatch(setVerticalTemplatesCurrentPage(page));
    return await getVerticalTemplatesApi(new URLSearchParams({
      limit: TEMPLATES_PAGE_SIZE.toString(),
      page: page.toString()
    }));
  }
);

const pwaFormReducer = createSlice({
  name: "pwaFormReducer",
  initialState,
  reducers: {
    setVerticalTemplatesCurrentPage(state, action: PayloadAction<number>) {
      state.verticalTemplates.currentPage = action.payload;
    },
    goToNextPage(state) {
      state.navigation.currentPage += 1;
      if (state.navigation.maxUnlockedPage < state.navigation.currentPage) {
        state.navigation.maxUnlockedPage = state.navigation.currentPage;
      }
    },
    goToPrevPage(state) {
      if (state.navigation.currentPage !== 0) {
        state.navigation.currentPage -= 1;
      }
    },
    setPage(state, action: PayloadAction<number>) {
      state.navigation.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPwaFormData.fulfilled, (state, action) => {
      state.verticalTemplates.data = action.payload.verticalTemplatesData.results;
      state.verticalTemplates.count = action.payload.verticalTemplatesData.count;
      state.prelandingsOptions = action.payload.prelandingsData;

      state.userCloaks = action.payload.userWhiteIps
        .map(({ ip }) => ({ label: ip, value: ip }));
    });

    builder.addCase(updateVerticalTemplates.fulfilled, (state, action) => {
      state.verticalTemplates.data = action.payload.results;
      state.verticalTemplates.count = action.payload.count;
    });
    builder.addCase(getPreviewTemplateById.fulfilled, (state, action) => {
      state.selectedPreviewTemplate = action.payload;
    })
  }
});


export const getVerticalTemplates = (state: RootState) => state.pwaFormReducer.verticalTemplates;

export const getPrelandingsOptions = (state: RootState) => state.pwaFormReducer.prelandingsOptions;

export const getPwaNavigation = (state: RootState) => state.pwaFormReducer.navigation;
export const getUserCloaksList = (state: RootState) => state.pwaFormReducer.userCloaks;
export const getSelectedPreviewTemplate = (state: RootState) => state.pwaFormReducer.selectedPreviewTemplate;


export const {
  goToNextPage,
  goToPrevPage,
  setPage,
  setVerticalTemplatesCurrentPage,
} = pwaFormReducer.actions;

export default pwaFormReducer.reducer;
