import React from "react";
import styled from "styled-components";
import { Checkbox as CheckboxMui } from "@mui/material";

import { theme } from "../../global/theme";


type Props = {
  id?: string;
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  name?: string,
}

const StyledCheckbox = styled(CheckboxMui)`
  width: 24px;
  height: 24px;

  &.Mui-checked {
    color: ${theme.redPrimary} !important;
  }
`;

export const Checkbox = ({ checked, onChange, id, name }: Props) => {
  return (
    <StyledCheckbox id={id} onChange={onChange} checked={checked} name={name} />
  );
};


