import React from "react";
import styles from "./Pagination.module.scss";
import { theme } from "../../global/theme";
import PaginationMUI from '@mui/material/Pagination';
import { useSearchParams } from "react-router-dom";
import { params } from "../../api/helpers/constants";

type Props = {
  listLength: number;
  defaultRowsPerPage?: number;
}

export const ParamsPagination: React.FC<Props> = ({
  listLength,
  defaultRowsPerPage = 10,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rowsPerPage = Number(searchParams.get(params.limit)) || defaultRowsPerPage;
  const currentPage = Number(searchParams.get(params.page)) || 1;
  const pagesTotal = Math.ceil(listLength / rowsPerPage);

  return (
    <div className={styles.pagination}>
      <PaginationMUI
        page={currentPage}
        count={pagesTotal}
        shape="rounded"
        onChange={(_, page) => setSearchParams(_params => {
          _params.set(params.page, page.toString());
          return _params;
        })}
        sx={{
          '& .MuiPaginationItem-root': {
            color: '#d5d5e7',
            '&:hover': {
              backgroundColor: '#eceaea',

            },
            '&.Mui-selected': {
              backgroundColor: theme.greyBackground,
              color: theme.redPrimary,
            },
          },
        }}
      />
    </div>
  )
}