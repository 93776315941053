import { formatTimestampToServerDate } from "./formatData";

export enum SelectedDateRange {
  Today = "Today",
  Yesterday = "Yesterday",
  ThisWeek = "ThisWeek",
  Last7Days = "Last7Days",
  ThisMonth = "ThisMonth",
  LastMonth = "LastMonth",
  Last30Days = "Last30Days",
  CustomDate = "CustomDate",
}

export function getDateRange(range: SelectedDateRange): (null | { startDate: string, endDate: string }) {
  const today = formatTimestampToServerDate(new Date().toDateString());
  const date = new Date();
  switch (range) {
    case SelectedDateRange.Today:
      return {
        endDate: today,
        startDate: today,
      }
    case SelectedDateRange.Yesterday:
      let yesterday = date;
      yesterday.setDate(yesterday.getDate() - 1);

      return {
        startDate: yesterday.toISOString().split('T')[0],
        endDate: yesterday.toISOString().split('T')[0],
      }
    case SelectedDateRange.ThisWeek:
      let dayOfTheWeek = (new Date()).getDay() || 7; // || 7 for Sunday
      let date_before = new Date();
      date_before.setDate(date_before.getDate() - (dayOfTheWeek - 1));

      return {
        startDate: date_before.toISOString().split('T')[0],
        endDate: today,
      }
    case SelectedDateRange.Last7Days:
      date.setDate(date.getDate() - 7);

      return {
        startDate: date.toISOString().split('T')[0],
        endDate: today,
      }
    case SelectedDateRange.ThisMonth:
      date.setDate(1)
      const firstDateOfCurrentMonth = date.toISOString().split('T')[0];

      return {
        startDate: firstDateOfCurrentMonth,
        endDate: today,
      }
    case SelectedDateRange.LastMonth:

      let lastMonth = new Date();
      lastMonth.setDate(0);
      const lastDayOfPreviousMonth = new Date(lastMonth);
      lastMonth.setDate(1);
      const firstDayOfPreviousMonth = new Date(lastMonth);

      return {
        startDate: firstDayOfPreviousMonth.toISOString().split('T')[0],
        endDate: lastDayOfPreviousMonth.toISOString().split('T')[0],
      }
    case SelectedDateRange.Last30Days:
      date.setDate(date.getDate() - 30);
      return {
        startDate: date.toISOString().split('T')[0],
        endDate: today,
      }
    default:
    case SelectedDateRange.CustomDate:
      return null;
  }
}

export function getDateRangeValues() {
  const res: { [key: string]: string } = {};
  const ranges = Object.values(SelectedDateRange);
  ranges.pop();
  for (const selectedRange of ranges) {
    const range = getDateRange(selectedRange as SelectedDateRange);
    res[`${range!.startDate}-${range!.endDate}`] = selectedRange;
  }

  return res;
}