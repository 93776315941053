import Slider from "@mui/material/Slider/Slider";
import React, { useState } from "react";
import { Accordion } from "../../../components/Accordion/Accordion";
import styles from "./Content.module.scss";
import { PagePreview } from "../../../components/PagePreview/PagePreview";
import { FormInput } from "../../../components/FormInput/FormInput";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../helpers/formikHelpers";
import { FormTextArea } from "../../../components/TextArea/FormTextArea";
import { PagesNavigation } from "../PagesNavigation/PagesNavigation";
import {getTranslation } from "../../../api/localization";
import FormSelect from "../../../components/FormSelect";
import libraryIcon from "../../../img/library.svg";
import { DescriptionLibraryModal } from "../../../components/Modals/DescriptionLibraryModal/DescriptionLibraryModal";
import useLanguages from "../../../store/hooks";
import styled from "styled-components";


const ratingKeys = [
  'application.one_star',
  'application.two_star',
  'application.three_star',
  'application.four_star',
  'application.five_star',
];

const CustomSlider = styled(Slider)({
  '& .MuiSlider-valueLabel': {
    backgroundColor: '#01875F',
    color: '#ffffff',
    fontSize: '12px',
    borderRadius: '16px',
  },

  '& .MuiSlider-thumb': {
    borderRadius: '12px',
    width: "12px",
    height: "26px",
    border: "2px solid #D8DCE0"
  }
});

const updateRating = (
  setFieldValue: (field: string, value: any) => unknown,
  values: PwaFormValues,
  fieldNumber: number,
) => async (_: Event, value: number | number[]) => {
  await setFieldValue(ratingKeys[fieldNumber - 1], value);
  const ratings = [
    values.application.one_star || 0,
    values.application.two_star || 0,
    values.application.three_star || 0,
    values.application.four_star || 0,
    values.application.five_star || 0,
  ];
  ratings[fieldNumber - 1] = value as number;

  const numerator = ratings[0]
    + ratings[1] * 2
    + ratings[2] * 3
    + ratings[3] * 4
    + ratings[4] * 5;
  const denominator = ratings[0]
    + ratings[1]
    + ratings[2]
    + ratings[3]
    + ratings[4];
  const average = Math.round(numerator / denominator * 10) / 10;
  await setFieldValue('application.rating', average);
};

export const Content = () => {
  const { values, setFieldValue } = useFormikContext<PwaFormValues>();
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const oldMarket = values.vertical!.template === "old_play_market";

  const languages = useLanguages();

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <p className={styles.subTitle}>
          Проведите текстовые настройки страницы PWA
        </p>

        <label className={styles.label}> Локализация (не обязательно)</label>
        <FormSelect
          name="application.language"
          options={languages}
          isSearchable
          placeholder="Выберите язык"
          onChange={async (value) => {
            getTranslation(value).then(
              async (application) => setFieldValue(
                'application',
                {
                  ...values.application,
                  ...application,
                  language: value,
                }
              )
            );
          }}
        />

        <div className={styles.accordionsBlock}>
          <Accordion title={"Основное"} id="acc-1" defaultExpanded>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Название</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.name"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Автор </label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.author"
                />
              </div>
            </div>
              <>
                <div className={styles.inputRow}>
                  <div className={styles.inputItem}>
                    <label>Категория</label>
                    <FormInput
                      placeholder="Введите текст"
                      name="application.category"
                    />
                  </div>
                  <div className={styles.inputItem}>
                    <label>Категория (надпись) </label>
                    <FormInput
                      placeholder="Введите текст"
                      name="application.category_text"
                    />
                  </div>
                </div>
              </>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Реклама</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.advertising"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Возраст </label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.age"
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Рейтинг текст (мобильное)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.rating_text"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Размер (мобильное) </label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.size"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Возраст текст (мобильное) </label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.age_text"
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Рекомендация (ПК)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="recommendPC"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Список желаний (ПК)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="wishlistPC"
                />
              </div>
            </div>
          </Accordion>
          <Accordion title={"Кнопка установки"} id="acc-1">
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Установить</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.install"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Инициализация</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.installation"
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Загрузка</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.download"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Открыть</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.open"
                />
              </div>
            </div>
          </Accordion>
          <Accordion title={"Описание"} id="acc-2">
            <div className={styles.libraryWrapper}>

              <div className={styles.libraryDescription}>
                <img src={libraryIcon} alt="library"/>
                <div>
                  <div className={styles.libraryDescription_title}>Библиотека описаний (не обязательно)</div>
                  <div className={styles.libraryDescription_subtitle}>Вы можете выберите готовое описание в библиотеке
                  </div>
                </div>
              </div>
              <button
                type="button"
                className={styles.libraryBtn}
                onClick={() => setShowLibraryModal(true)}
              >
                Выбрать из библиотеки
              </button>

            </div>

            <div className={styles.inputRowTextArea}>
              <div className={styles.inputItem}>
                <label>Описание</label>
                <FormTextArea
                  name="application.description"
                  bg="white"
                />
              </div>
            </div>

            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>О приложении (Моб)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.about"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Подробнее</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.read_more"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Скрыть</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.hide_reviews_text"
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Обновления(ПК)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.updatePC"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Описания обновления(ПК)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.aboutPC"
                />
              </div>
            </div>

              <div className={styles.inputRow}>
                <div className={styles.inputItem}>
                  <label>Что нового?(надпись)</label>
                  <FormInput
                    placeholder="Введите текст"
                    name="application.whats_new_text"
                  />
                </div>
                <div className={styles.inputItem}>
                  <label>Что нового</label>
                  <FormInput
                    placeholder="Введите текст"
                    name="application.whats_new"
                  />
                </div>
              </div>

          </Accordion>
          <Accordion title={"Рейтинг"} id="acc-3">
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Отзывы</label>
                <FormInput
                  placeholder="Введите цифру"
                  name="application.reviews"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Рейтинг</label>
                <FormInput
                  placeholder="Введите цифру"
                  name="application.rating"
                  min="1"
                  max="5"
                  step="0.1"
                  type="number"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Кл. отзывов</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.reviews_count"
                  type="number"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Всего (ПК)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.total"
                />
              </div>
            </div>

            <div className={styles.slidersColumn}>
              <div className={styles.sliderItem}>
                <p>5</p>
                <CustomSlider
                  onChange={updateRating(setFieldValue, values, 5)}
                  valueLabelDisplay="auto"
                  sx={{ height: 17, color: "#01875F" }}
                  value={values.application.five_star}
                  aria-label="Disabled slider"
                />
              </div>
              <div className={styles.sliderItem}>
                <p>4</p>
                <CustomSlider
                  onChange={updateRating(setFieldValue, values, 4)}
                  valueLabelDisplay="auto"
                  sx={{ height: 17, color: "#01875F" }}
                  value={values.application.four_star}
                  aria-label="Disabled slider"
                />
              </div>
              <div className={styles.sliderItem}>
                <p>3</p>
                <CustomSlider
                  onChange={updateRating(setFieldValue, values, 3)}
                  valueLabelDisplay="auto"
                  sx={{ height: 17, color: "#01875F" }}
                  value={values.application.three_star}
                  aria-label="Disabled slider"
                />
              </div>
              <div className={styles.sliderItem}>
                <p>2</p>
                <CustomSlider
                  onChange={updateRating(setFieldValue, values, 2)}
                  valueLabelDisplay="auto"
                  sx={{ height: 17, color: "#01875F" }}
                  value={values.application.two_star}
                  aria-label="Disabled slider"
                />
              </div>
              <div className={styles.sliderItem}>
                <p>1</p>
                <CustomSlider
                  onChange={updateRating(setFieldValue, values, 1)}
                  valueLabelDisplay="auto"
                  sx={{ height: 17, color: "#01875F" }}
                  value={values.application.one_star}
                  aria-label="Disabled slider"
                />
              </div>
            </div>
          </Accordion>
          <Accordion title={"Доп. информация"} id="acc-4">
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Доп. информация</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.additional_information"
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Разработчик</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.developer"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Обновлено</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.updated"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Дата обновления</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.update_date"
                />
              </div>


            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Размер(надпись)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.size_text"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Размер</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.size"
                />
              </div>
            </div>

            <div className={styles.inputRow}>
              <div className={styles.inputItem}>
                <label>Установок</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.installs"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Количество установок</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.installs_count"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Текущая версия (надпись)</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.current_version_text"
                />
              </div>
              <div className={styles.inputItem}>
                <label>Текущая версия</label>
                <FormInput
                  placeholder="Введите текст"
                  name="application.current_version"
                />
              </div>
            </div>
          </Accordion>
          {/*<Accordion title="Cookies" id="acc-6">*/}
          {/*  <div className={styles.inputRow}>*/}
          {/*    <div className={styles.inputItem}>*/}
          {/*      <label>Запрос cookies</label>*/}
          {/*      <FormInput*/}
          {/*        placeholder="Введите текст"*/}
          {/*        name="application.cookies_request"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className={styles.inputRow}>*/}
          {/*    <div className={styles.inputItem}>*/}
          {/*      <label>Подтверждение</label>*/}
          {/*      <FormInput*/}
          {/*        placeholder="Введите текст"*/}
          {/*        name="application.cookies_accept"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className={styles.inputItem}>*/}
          {/*      <label>Отказ</label>*/}
          {/*      <FormInput*/}
          {/*        placeholder="Введите текст"*/}
          {/*        name="application.cookies_reject"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Accordion>*/}
        </div>
        <PagesNavigation nexButtonDisabled={false}/>
      </div>
      <div className={styles.column}>
        <PagePreview/>
      </div>
      {showLibraryModal &&
        <DescriptionLibraryModal
          languages={languages}
          hideModal={() => setShowLibraryModal(false)}/>}
    </div>
  );
};
