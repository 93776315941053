import { authorizedRequests } from "./auth";
import { BASE_URL } from "./constants";
import { PixelData, PixelDataGet } from "../types/pixelApi";
import { PaginatedResults } from "./helpers/types";



export async function getPixelList(params: URLSearchParams, signal: AbortSignal) {
  const response = await authorizedRequests.get(
    BASE_URL + '/pixel/?' + params,
    { signal }

  );
  return response.data as PaginatedResults<PixelDataGet[]>;
}


export async function getPixelDataAPI(id: string) {
  const response = await authorizedRequests.get(
    BASE_URL + `/pixel/${id}/`
  );
  return response.data as PixelDataGet;
}

export async function sendPixel(pixelData: PixelData) {
  const response = await authorizedRequests.post(
    BASE_URL + '/pixel/',
    pixelData
  );

  return response.data as PixelData;
}

export async function deletePixel(id: number) {
  await authorizedRequests.delete(
    BASE_URL + `/pixel/${id}/`
  );
}

export async function patchPixelData(id: number, pixelData: PixelData) {
  const response = await authorizedRequests.patch(
    BASE_URL + `/pixel/${id}/`,
     pixelData,
  );
  return response.data as PixelDataGet;
}
