import React, { useMemo } from "react";
import styles from "./Cloaka.module.scss";
import { FormInput } from "../../../components/FormInput/FormInput";
import { FormSelect } from "../../../components/FormSelect/FormSelect";
import { useAppSelector } from "../../../store/hooks";
import {
  getCloakEnumsStatus,
  getCloakEnumsTypes, getCloakEnumWhitePageType,
  getCountriesOptions, getWordPressEnumsTheme
} from "../../../store/reducers/globalDataReducer";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../helpers/formikHelpers";
import { FormMultiSelect } from "../../../components/FormSelect/FormMultiSelect";
import { PagesNavigation } from "../PagesNavigation/PagesNavigation";
import { IpMultiInput } from "../../../components/Select/IpMultiInput";


export const Cloaka = () => {
  const { values, errors } = useFormikContext<PwaFormValues>();
  const { setFieldValue } = useFormikContext<PwaFormValues>();
  const cloakaStatus = useAppSelector(getCloakEnumsStatus);
  const cloakaType = useAppSelector(getCloakEnumsTypes);
  const cloakaWhitePageType = useAppSelector(getCloakEnumWhitePageType);
  const wordPressTheme = useAppSelector(getWordPressEnumsTheme);
  const countriesList = useAppSelector(getCountriesOptions);

  const countriesListOptions = useMemo(() => {
    return countriesList
      .map(country => ({
        label: country.name,
        value: country.id.toString()
      }));
  }, [countriesList]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div>
          <div className={styles.inputContainer}>
            <label className={styles.label}>Тип клоаки</label>
            <FormSelect
              name="cloak.type"
              options={cloakaType}
              placeholder="Выберите тип"
              onChange={async (value) => {
                const type = cloakaType.find(type => type.value === value);
                if (type) await setFieldValue('cloak.type', value);
              }}
            />
          </div>

          <div className={styles.inputContainer}>
            <label className={styles.label}>Статус клоаки</label>
            <FormSelect
              name="cloak.status"
              options={cloakaStatus}
              placeholder="Выберите статус"
              onChange={async (value) => {
                const status = cloakaStatus.find(status => status.value === value);
                if (status) await setFieldValue('cloak.status', value);
              }}
            />
          </div>

          <div className={styles.inputContainer}>
            <label className={styles.label}>Тип вайт пэйджа</label>
            <FormSelect
              name="cloak.whitepage_type"
              options={cloakaWhitePageType}
              placeholder="Выберите статус"
              onChange={async (value) => {
                const status = cloakaWhitePageType.find(status => status.value === value);
                if (status) await setFieldValue('cloak.whitepage_type', value);
              }}
            />
          </div>


          {values.cloak.whitepage_type === "wp" ?
            <div className={styles.inputContainer}>
              <label className={styles.label}>Тема WordPress</label>
              <FormSelect
                name="cloak.wordpress_theme"
                options={wordPressTheme}
                placeholder="Выберите тему"
                onChange={async (value) => {
                  const type = wordPressTheme.find(type => type.value === value);
                  if (type) await setFieldValue('cloak.wordpress_theme', value);
                }}
              />
            </div> :
            <div className={styles.inputContainer}>
              <label>Источник вайт пэйджа</label>
              <FormInput
                name="cloak.white_url"
                placeholder="Введите ссылку"
              />
            </div>
          }
        </div>
        <div>
          <div className={styles.inputContainer}>
            <label className={styles.label}>Мульти Гео (не обязательное)</label>
            <FormMultiSelect
              name="cloak.countries"
              options={countriesListOptions}
              placeholder="Все страны"
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.label}>Список белых ip-адрессов</label>
            <IpMultiInput
              values={values.cloak?.white_ips?.map(ip => ({ label: ip, value: ip }))}
              onChange={(value) => setFieldValue('cloak.white_ips', value)}
            />
          </div>
        </div>
      </div>
      <PagesNavigation nexButtonDisabled={
        !values.cloak.status
        || !values.cloak.type
        || !!errors.cloak?.white_url
        || !values.cloak.whitepage_type
      }/>

    </div>
  );
};