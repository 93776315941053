import React from "react";
import { useSearchParams } from "react-router-dom";

import { SortIcon } from "./SortIcon";
import { Ordering } from "../../types/global";
import { params } from "../../api/helpers/constants";

type Props = {
  sortsBy: string,
  defaultSort?: string,
}

/**
 * @param sortsBy What this icon sorts by
 * @param defaultSort What sorting is considered active if param value is undefined
 */
export const ParamsSortIcon: React.FC<Props> = ({
  sortsBy,
  defaultSort,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeSort = searchParams.get(params.ordering) || defaultSort;

  return <SortIcon
    onClick={() => {
      setSearchParams(_params => {
        _params.set(
          params.ordering,
          activeSort === Ordering.asc + sortsBy
            ? Ordering.desc + sortsBy
            : Ordering.asc + sortsBy
        );
        return _params;
      });
    }}
    isActive={activeSort === Ordering.asc + sortsBy || activeSort === Ordering.desc + sortsBy}
    isDesc={activeSort === Ordering.desc + sortsBy}
  />;
}