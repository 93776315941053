import React from "react";
import { useField } from "formik";

import styles from "./FormSelect.module.scss";
import { Select } from "../Select/Select";

type Props = {
  options: { label: string; value: string }[];
  defaultValue?: string | number;
  value?: string | number;
  name: string;
  onChange?: (event: string) => void,
  placeholder?: string;
  isSearchable?: boolean;
};

export const FormSelect: React.FC<Props> = ({
  name,
  value,
  options,
  placeholder,
  onChange,
  isSearchable
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <Select
        isSearchable={isSearchable}
        placeholder={placeholder}
        value={value === "" ? "" : (value || field.value || "")}
        options={options}
        onSet={onChange || (value => helpers.setValue(value))}
        onBlur={() => helpers.setTouched(true)}
        isvalid={!(meta.touched && meta.error)}
      />
      {meta.touched && meta.error && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </>
  );
};
