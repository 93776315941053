import ReactSelect, { MultiValue } from 'react-select';

import { StyledSelect } from './Select.styled';
import { SelectList } from "../../types/global";

type Props = {
  options: SelectList;
  values?: SelectList;
  onBlur?: () => void;
  onChange: (values: MultiValue<{ label: string, value: string }>) => void,
  placeholder?: string;
};

export const MultiSelect = ({
  options,
  values,
  onBlur,
  onChange,
  placeholder,
}: Props) => {
  return (
    <StyledSelect $theme="normal" $isvalid $icon="none">
      <ReactSelect
        isMulti
        classNamePrefix="styled-select"
        value={values || null}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        onBlur={onBlur}
        noOptionsMessage={()=> "Нет вариантов"}
      />
    </StyledSelect>
  );
};
