import React from "react";
import styled from "styled-components";

export const StyledPreviewRatingSlider = styled('div')<{
  $value: number;
}>`
  width: 100%;
  position: relative;
  height: 8px;
  border-radius: 4px;
  background-color: #DBDBDB;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 8px;
    border-radius: 4px;
    width: ${({ $value }) => $value}%;
    background-color: #01875F;
  }
`;

type Props = {
  value?: number,
}

export const PreviewRatingSlider: React.FC<Props> = ({ value }) => {
  return <StyledPreviewRatingSlider $value={value || 0} />
};

