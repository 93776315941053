import styled, { css, RuleSet } from "styled-components";
import { theme } from "../../global/theme";
import { SelectIconType, SelectThemeType } from "./Select";
import burgerMenu from "../../img/burgerMenu.svg";

const iconStyles: { [key in SelectIconType]: RuleSet<object> } = {
  none: css``,
  menu: css`
    &__control {
      &::before {
        content: "";
        background: url(${burgerMenu});
        background-size: contain;
        display: block;
        width: 20px;
        height: 20px;
        margin-left: 16px;
      }
    }
    
    &__value-container {
      padding-left: 12px;
    }
  `,
};

const themeStyles: { [key in SelectThemeType]: RuleSet<object> } = {
  normal: css``,
  grey: css`
    &__dropdown-indicator {
      color: ${theme.white};

      &:hover {
        color: ${theme.white};
      }
    }
    &__control {
      min-width: 120px;
    }
  `,
};

export const StyledSelect = styled(`div`)<{
  $theme: SelectThemeType;
  $isvalid: boolean;
  $icon: SelectIconType;
}>`
  width: 100%;
  
  .styled-select {
    &__container {
      width: 100%;
      min-width: 180px;
    }
    
    
    &__single-value {
      color: ${({ $theme }) => $theme === "grey"
        ? theme.white
        : 'inherit'};
    }

    &__control {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      min-height: 50px;
      border-radius: 8px;
      border: ${({ $isvalid, $theme }) => (
        $isvalid
          ? ($theme === "normal" ? `1px solid ${theme.lightGrey}` : "none")
          : `1px solid ${theme.redPrimary}`
      )};
      
      background: ${({ $theme }) => $theme === "grey" 
        ? theme.greyPrimary 
        : theme.white};

      &:focus,
      &:focus-visible,
      &:focus-within {
        border:  1px solid ${theme.greyPrimary};
        outline: none;
        box-shadow: none;
      }
    }

    &__indicator-separator {
      display: none;
      opacity: 0;
    }

    &__value-container {
      padding-left: 16px;
    }

    &__placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }

    &__option {
      color: ${theme.greyPrimary};
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      cursor: pointer;
      padding: 12px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${theme.greyBackground};
      }

      &--is-focused {
        background: none;
        color: ${theme.blackButton};
        
        &:active {
          background: ${theme.greyBackground};
          outline: none;
          border: none;
          box-shadow: none;
        }
      }

      &--is-selected {
        background: none;
        font-weight: 700;
        color: ${theme.black};
      }
    }
    
    &__menu {
      z-index: 2;
    }

    &__menu-list {
      padding: 12px 24px;
      border-radius: 8px;
      box-shadow: 0 4px 16px 0 #0000001F;
    }
    
    &__menu-list {
     
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #0000001A;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: ${theme.greyPrimary};
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${theme.greyPrimary};
      }
    }
    
    &__multi-value {
      border-radius: 12px;
      background: ${theme.greyBackground};
      margin: 0;
      
      &__remove {
        border-radius: 12px;
        cursor: pointer;
      }


      &__label {
        padding: 1px 1px 1px 8px;
      }
    }
    
    &__value-container--is-multi {
      padding: 12px 16px;
      row-gap: 12px;
      column-gap: 8px;
    }
    
    &__clear-indicator {
      cursor: pointer;
      color: ${theme.redPrimary};
    }
    
    ${({ $icon }) => iconStyles[$icon]}
    ${({ $theme }) => themeStyles[$theme]}
  }

`;