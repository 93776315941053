import { PWAName } from "./PWAName/PWAName";
import Tracker from "./Tracker/Tracker";
import { Design } from "./Design/Design";
import { Content } from "./Content/Content";
import { Comments } from "./Comments/Comments";
import { Cloaka } from "./Cloaka/Cloaka";

export const TABS = [
  PWAName,
  Tracker,
  Cloaka,
  Design,
  Content,
  Comments,
];

export const MAX_PAGE_NUMBER = TABS.length - 1;