import React, { useState } from "react";
import styles from './FAQ.module.scss'
import { useAppDispatch } from "../../store/hooks";
import { AccordionFaq } from "./AccordionFaq/AccordionFaq";
import verticalScreen from "../../img/printScreens/verticalScreen.png";


const menu = [
  { id: 0, name: "Все" },
  { id: 1, name: "Привязка CloudFlare" },
  { id: 2, name: "Создание PWA" },
  { id: 3, name: "Работа с Командой" },
  { id: 4, name: "Работа с параметрами" }
];

const pointsVertical = [
  "1.Загрузка своих картинок. По данной кнопке Вы можете загрузить своё оформление PWA.",
  "2.Выбираете вертикаль которую Вы собираетесь использовать. В зависимости от выбранной Вертикали вам будут предложены картинки из базы.",
  "3.Выбираете темплейт который Вы собираетесь использовать.",
  "4.Выбираете один из предоставленных шаблонов для Вертикали."
];


export const FAQ = () => {
  const dispatch = useAppDispatch();

  const [activeTopic, setActiveTopic] = useState(0);

  return (
    <>
      <div className="title-container">
        <h2>FAQ</h2>
      </div>
      <div className="subtitle">Служба поддержки</div>
      {/*<div className={styles.searchContainer}>*/}
      {/*  <div className={styles.text}>Найдите ответ на свой вопрос</div>*/}
      {/*  <div className={styles.search}>*/}
      {/*    <Search value={name} onChange={(e) => setName(e.target.value)}/>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={styles.contentContainer}>
        {/*<div className={styles.menu}>*/}
        {/*  {menu.map((topic, index) =>*/}
        {/*    <div*/}
        {/*      key={topic.name}*/}
        {/*      className={activeTopic === topic.id ? styles.topicActive : styles.topic}*/}
        {/*      onClick={() => {*/}
        {/*        if (index === topic.id) {*/}
        {/*          setActiveTopic(topic.id)*/}
        {/*        }*/}

        {/*      }}*/}
        {/*    >*/}
        {/*      {topic.name}*/}
        {/*    </div>)}*/}
        {/*</div>*/}
        <div className={styles.accordions}>
          <AccordionFaq
            question="Добавление или покупка домена"
            answer="Вы получите домен автоматически при оплате PWA. Стоимость одного домена 6 долларов."
          />
          <AccordionFaq
            question="Наполнение комментариев"
            answer="Вы можете написать неограниченное количество комментариев при создании или редактировании PWA во вкладке КОММЕНТАРИИ. Обязательно указывайте автора и сам комментарий, все остальное по желанию."
          />

          <AccordionFaq
            question="Настройка Вертикали"
            points={pointsVertical}
            img={verticalScreen}
          />
          <AccordionFaq
            question="Вопросы по оплате"
           answer="@yaroslava_support_wwa"
          />
        </div>
      </div>
    </>
  )

}