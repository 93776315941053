import React, { useEffect } from "react";

import { Header } from "../Header/Header";
import { SideBar } from "../SideBar/SideBar";
import styles from "./MainLayout.module.scss";
import { getPwaGlobalData } from "../../store/reducers/globalDataReducer";
import { useAppDispatch } from "../../store/hooks";
import { Outlet } from "react-router-dom";

type Props = {
  children?: React.ReactNode
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPwaGlobalData());
  }, [dispatch]);

  return (
    <div className={styles.layout}>
      <Header/>
      <div className={`${styles.sidePaddings} ${styles.pageContent}`}>
        <div>
          <SideBar/>
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
