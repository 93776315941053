export enum TransactionPurpose {
  write_off = "write_off",
  refill = "refill",
}


export type Transaction = {
  id: number,
  created_at: string,
  status: string,
  amount: string,
  purpose: TransactionPurpose,
  description: string,
}

export type TransactionsOptions = {
  name: string,
  description: string,
  renders: [],
  parses: [],
  status_choices: { [key: string]: string }
}


export type CryptoWallet = {
  crypto_currency_wallet: string;
}

export type Balances = {
  user: string,
  amount: string
}

export type Users = {
  id: number,
  username: string,
}
