import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { StatisticsClicks, StatisticsGeneral } from "../../types/statistics";
import { getClicksStatistics, getGeneralStatistics, } from "../../api/statistics";
import { RootState } from "../store";
import { AxiosError } from "axios";
import { ErrorMessage } from "../../types/api";


type InitialState = {
  generalStatistics: {
    data: StatisticsGeneral[],
    count: number,
    total_clicks: number,
    total_installs: number,
    total_leads: number,
    total_sales: number,
    total_opens: number,
  },
  clicksStatistics: {
    data: StatisticsClicks[],
    count: number,
  }
}

const initialState: InitialState = {
  generalStatistics: {
    data: [],
    count: 0,
    total_clicks: 0,
    total_installs: 0,
    total_leads: 0,
    total_sales: 0,
    total_opens: 0,
  },
  clicksStatistics: {
    data: [],
    count: 0,
  }
};

export const getGeneralStatisticsThunk = createAsyncThunk<
  Awaited<ReturnType<typeof getGeneralStatistics>>,
  URLSearchParams
>(
  'statisticsReducer/getGeneralStatisticsThunk',
  async (params, { rejectWithValue, signal }) => {
    try {
      return await getGeneralStatistics(params, signal);
    } catch (err: any) {
      const error = err as AxiosError<ErrorMessage>;
      if (!error.response) {
        throw error
      }

      throw rejectWithValue(error.response!.data);
    }
  },
);

export const getClicksStatisticsThunk = createAsyncThunk<
  Awaited<ReturnType<typeof getClicksStatistics>>,
  URLSearchParams
>(
  'statisticsReducer/getClicksStatisticsThunk',
  async (params, { rejectWithValue, signal }) => {
    try {
      return await getClicksStatistics(params, signal);
    } catch (err: any) {
      const error = err as AxiosError<ErrorMessage>;
      if (!error.response) {
        throw error
      }

      throw rejectWithValue(error.response!.data);
    }
  },
);

const statisticsReducer = createSlice({
  name: "statisticsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralStatisticsThunk.fulfilled, (state, action) => {
      state.generalStatistics.data = action.payload.results;
      state.generalStatistics.count = action.payload.count;
      state.generalStatistics.total_clicks = action.payload.total_clicks;
      state.generalStatistics.total_installs = action.payload.total_installs;
      state.generalStatistics.total_leads = action.payload.total_leads;
      state.generalStatistics.total_sales = action.payload.total_sales;
      state.generalStatistics.total_opens = action.payload.total_opens;
    });

    builder.addCase(getClicksStatisticsThunk.fulfilled, (state, action) => {
      state.clicksStatistics.data = action.payload.results;
      state.clicksStatistics.count = action.payload.count;
    });
  },
});


export const getGeneralStatisticsData = (state: RootState) => state.statisticsReducer.generalStatistics;
export const getClicksStatisticsData = (state: RootState) => state.statisticsReducer.clicksStatistics;

export default statisticsReducer.reducer;