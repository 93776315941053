import styles from "./UploadModal.module.scss";
import closeIcon from "../../img/closeIcon.svg";
import { useEffect, useRef, useState } from "react";
import uploadIcon from "../../img/tabletools/download.svg";
import { Button } from "../Button/Button";
import React from "react";

type Props = {
  handleClose: () => void;
  handleSave: (icon: string, imgArray: string[]) => void;
};

type ImgIconProps = {
  saveIcon: (icon: string) => void;
};

type ImgGalleryProps = {
  saveImgGal: (imgArray: []) => void;
};

function ImgGallery({ saveImgGal }: ImgGalleryProps) {
  const [imgArray, setImgArray] = useState<any | null>([]);
  const inputRef = useRef<any>();
  const imgMax = 4;

  useEffect(() => {
    saveImgGal(imgArray);
  }, [imgArray]);

  function removeFile(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    event.preventDefault();
    const tmpArray = imgArray.filter((_: any, ind: number) => ind !== index);
    setImgArray(tmpArray);
  }

  function handleFileUpload(files: FileList | null) {
    if (files) {
      if (files[0].size > 20 * 1024 * 1024) {
        alert("Размер файла не должен превышать 20Мб");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const tmpArray = [...imgArray];
        tmpArray.push(reader.result as string);
        setImgArray(tmpArray);
      };

      reader.readAsDataURL(files[0]);
    } else {
      alert("Не удалось загрузить файл");
    }
  }

  return (
    <div className={styles.galleryWrapper}>
      <input
        ref={inputRef}
        className={styles.hidden}
        type="file"
        onChange={(e) => handleFileUpload(e.target?.files)}
      />
      {imgArray.length === 0 ? (
        <div
          className={styles.previewContainer}
          onClick={() => inputRef.current.click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            handleFileUpload(e.dataTransfer.files);
          }}
        >
          <img
            src={uploadIcon}
            width={22}
            height={22}
            className={styles.icon}
            alt="uplaod icon"
          />
          <p className={styles.uploadText}>Превью</p>
        </div>
      ) : (
        imgArray.map((item: any, index: number) => (
          <div
            className={`${styles.previewContainer} ${styles.hoverContainer}`}
            key={index + "gallery"}
            onClick={(e: any) => removeFile(e, index)}
          >
            <img src={item} width={202} height={184} alt="item Icon"/>
          </div>
        ))
      )}
      {imgArray.length < imgMax && imgArray.length > 0 && (
        <div
          className={`${styles.previewContainer} `}
          onClick={() => inputRef.current.click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            handleFileUpload(e.dataTransfer.files);
          }}
        >
          <img
            src={uploadIcon}
            width={22}
            height={22}
            className={styles.icon}
            alt="upload Icon"
          />
          <p className={styles.uploadText}>Превью</p>
        </div>
      )}
    </div>
  );
}

function IconUpload({ saveIcon }: ImgIconProps) {
  const [icon, setIcon] = useState<any | null>(null);
  const inputRef = useRef<any>();

  function handleFileUpload(files: FileList | null) {
    if (files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setIcon(reader.result as string);
        saveIcon(reader.result!.toString());
      };

      reader.readAsDataURL(files[0]);
    } else {
      alert("Не удалось загрузить файл");
    }
  }

  function handleClick() {
    if (!icon) {
      inputRef.current.click();
    } else {
      inputRef.current.value = null;
      setIcon(null);
    }
  }

  return (
    <div
      className={styles.iconUpload}
      onClick={() => handleClick()}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        handleFileUpload(e.dataTransfer.files);
      }}
    >
      <input
        ref={inputRef}
        className={styles.hidden}
        type="file"
        onChange={(e) => handleFileUpload(e.target?.files)}
      />
      <div
        className={`${styles.previewContainer} ${icon ? styles.imageSet : ""}`}
      >
        {!icon ? (
          <>
            <img
              src={uploadIcon}
              width={22}
              height={22}
              className={styles.icon}
              alt="upload Icon"
            />
            <p className={styles.uploadText}>Иконка</p>
          </>
        ) : (
          <img src={icon} width={120} height={120} alt=" Icon"/>
        )}
      </div>
    </div>
  );
}

export const UploadModal = React.forwardRef(({
  handleSave,
  handleClose,
}: Props, _) => {
  const [icon, setIcon] = useState<string | null>(null);
  const [imgArray, setImgArray] = useState<string[]>([]);

  const handleSaveImg = (imgArray: string[]) => {
    setImgArray(imgArray);
  };

  const handleSaveIcon = (icon: string) => {
    setIcon(icon);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleContainer}>
        <h2>Загрузите ваши изображения</h2>
        <img
          onClick={handleClose}
          src={closeIcon}
          alt="close"
          width={24}
          height={24}
        />
      </div>
      <p className={styles.text}>
        Загрузите нужный вам файлы (максимально 5) Формат jpg, png Размер файла
        не больше 20 мб
      </p>
      <div className={styles.uploadContainer}>
        <span className={styles.item}>
          <IconUpload saveIcon={handleSaveIcon}/>
        </span>
        <span>
          <ImgGallery saveImgGal={handleSaveImg}/>
        </span>
      </div>
      <div className={styles.btnsContainer}>
        <span className={styles.btn}>
          <Button
            type="button"
            onClick={() => {
              handleSave(icon!, imgArray);
            }}
            disabled={!icon || imgArray.length < 4}
          >Сохранить</Button>
        </span>
        <span className={styles.btn}>
          <Button
            onClick={handleClose}
          >Отмена</Button>
        </span>
      </div>
    </div>
  );
})