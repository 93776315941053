import styles from "./TableTools.module.scss";
import penIcon from "../../img/tabletools/penIcon.svg";
import chartIcon from "../../img/tabletools/chartIcon.svg";
import deleteIcon from "../../img/tabletools/trashBinIcon.svg";
import dollarIcon from "../../img/tabletools/dollarIcon.svg";
import copyIcon from "../../img/tabletools/copy.svg";
import chainIcon from "../../img/tabletools/chain.svg";
import greyChainIcon from "../../img/tabletools/greyChain.svg";
import paymentInProcess from "../../img/tabletools/paymentInProcess.svg";
import circleWarning from "../../img/tabletools/circleWarning.svg";
import { useAppDispatch } from "../../store/hooks";
import { changePWAStatus, duplicatePWAThunk } from "../../store/reducers/pwaListReducer";
import { PWAGetListData, PwaState, Status } from "../../types/api";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ActivatePWAModal } from "../Modals/ActivatePWAModal/ActivatePWAModal";
import { DeletePWAModal } from "../Modals/DeletePWAModal/DeletePWAModal";
import { DuplicateModal } from "../Modals/DuplicateModal/DuplicateModal";
import { LinksModal } from "../Modals/LinksModal/LinksModal";
import { params } from "../../api/helpers/constants";
import { Tooltip } from "@mui/material";


type Props = {
  pwaData: PWAGetListData
}

export const TableTools = ({ pwaData }: Props) => {
  const dispatch = useAppDispatch();


  const [showActivatePWAModal, setShowActivatePWAModal] = useState(false);
  const [showDeletePWAModal, setDeletePWAModal] = useState(false);
  const [showDuplicatePWAModal, setDuplicatePWAModal] = useState(false);
  const [showChainModal, setChainModal] = useState(false);

  return (
    <div className={styles.tableTools}>
      {(pwaData.status === Status.work || pwaData.status === Status.stop) && <Tooltip title="Статистика">
          <button type="button" onClick={() => {
            dispatch(changePWAStatus({
              pwa_id: pwaData.id,
              state: pwaData.status === Status.work ? PwaState.stop : PwaState.start,
            }))
          }}>
              <Link to={`/statistics/?${params.pwa}=${pwaData.name}`}>
                  <img
                      src={chartIcon}
                      width={24}
                      height={24}
                      alt="pause Icon"
                  />
              </Link>
          </button>
      </Tooltip>}
      {pwaData.status === Status.error && <Tooltip title="Ошибка">
          <img src={circleWarning} width={24} height={24} alt="warning Icon"/>
      </Tooltip>}
      {pwaData.status === Status.not_paid && <Tooltip title="Оплатить 6$">
          <button
              type="button"
              onClick={() => setShowActivatePWAModal(true)}>
              <img src={dollarIcon} width={24} height={24} alt="dollar Icon"/>
          </button>
      </Tooltip>}
      {pwaData.status === Status.in_process && <Tooltip title="Оплата в процессе">
          <div>
              <img src={paymentInProcess} width={24} height={24} alt="dollar Icon"/>
          </div>
      </Tooltip>}
      <Tooltip title="Получить ссылки">
        <button type="button" onClick={() => setChainModal(true)}>
          <img src={pwaData.domain ? chainIcon : greyChainIcon} width={24} height={24} alt="chain Icon"
               className={styles.chainIcon}/>
        </button>
      </Tooltip>
      <Tooltip title="Редактировать PWA">
        <Link to={`/edit/${pwaData.id}`}>
          <img src={penIcon} width={24} height={24} alt="edit Icon"/>
        </Link>
      </Tooltip>
      <Tooltip title="Дублировать PWA">
        <button
          type="button"
          onClick={() => setDuplicatePWAModal(true)}
        ><img src={copyIcon} width={24} height={24} alt="copy Icon"/></button>
      </Tooltip>
      <Tooltip title="Удалить PWA">
        <button
          type="button"
          onClick={() => {
            setDeletePWAModal(true)
          }}
        >
          <img src={deleteIcon} width={24} height={24} alt="delete Icon"/>
        </button>
      </Tooltip>
      {showActivatePWAModal &&
          <ActivatePWAModal
              hideModal={() => setShowActivatePWAModal(false)}
              pwaId={pwaData.id}
          />}
      {showDeletePWAModal && <DeletePWAModal
          hideModal={() => setDeletePWAModal(false)}
          pwaId={pwaData.id}
      />}
      {showDuplicatePWAModal && <DuplicateModal
          duplicatePwa={async () => dispatch(duplicatePWAThunk(pwaData.id))}
          closeModal={() => setDuplicatePWAModal(false)}
      />}
      {
        showChainModal && pwaData.domain
        && <LinksModal domain={pwaData.domain} hideModal={() => setChainModal(false)}/>
      }
    </div>
  )
}