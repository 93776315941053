import React from "react";
import { useField, Field } from "formik";

import styles from "./FormTextArea.module.scss";

type Props = {
  name: string;
  placeholder?: string;
  bg?: string;
};

export const FormTextArea = ({
  name,
  placeholder,
  bg
}: Props) => {
  const [, meta, ] = useField(name);

  return (
    <>
      <Field
        name={name}
        as="textarea"
        style={{
          background: bg || "transparent",
          border: !(meta.touched && meta.error) ? "none" : "1px solid red",
          width: "100%",
          height: "97px",
          padding: "12px 16px",
          fontFamily: "Ubuntu, sans-serif",
        }}
        className={styles.textarea}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </>
  );
};
