import { Dayjs } from "dayjs";
import { useField } from "formik";

import { DatePicker } from "./DatePicker";
import styles from "../FormInput/FormInput.module.scss";
import React from "react";
import { convertDate } from "../../helpers/formatData";

type Props = {
  value?: Dayjs | null;
  defaultValue?: Dayjs;
  label?: string,
  className?: string,
  name: string,
}
export const FormDatePicker = ({ defaultValue, value, label, className, name}: Props) => {
  const [, meta, helpers] = useField(name);

  return (
    <>
      <DatePicker
        value={value}
        defaultValue={defaultValue}
        onChange={async (e: any) => {
          await helpers.setValue(convertDate(e.toString()));
          await helpers.setTouched(true);
        }}
        label={label}
        className={className}
        name={name}
      />
      {meta.touched && meta.error && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </>
  );
};
