type ErrorResponse = {
  response: {
    data: { [key: string]: string[] }
  }
};

export function getErrorsObject(errorData: ErrorResponse) {
  const res: { [key: string]: string } = {};
  for (const key in errorData.response.data) {
    res[key] = errorData.response.data[key][0];
  }

  return res;
}