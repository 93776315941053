import { SelectedDateRange } from "./dateHelpers";

export const filterStatusList = [
  { label: "Удалить", value: "Удалить" },
  { label: "Оплатить", value: "Оплатить" },
]

export const paginationRows = [10, 25, 50, 100];

export const dateRangeOptions = [
  { label: "Сегодня", value: SelectedDateRange.Today },
  { label: "Вчера", value: SelectedDateRange.Yesterday },
  { label: "На этой недели", value: SelectedDateRange.ThisWeek },
  { label: "Последние 7 дней", value: SelectedDateRange.Last7Days },
  { label: "В этом месяце", value: SelectedDateRange.ThisMonth },
  { label: "Предыдущий месяц", value: SelectedDateRange.LastMonth },
  { label: "Последние 30 дней", value: SelectedDateRange.Last30Days },
  { label: "Ваша дата", value: SelectedDateRange.CustomDate },
];


