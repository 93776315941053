import { authorizedRequests } from "./auth";
import { BASE_URL } from "./constants";
import { Tariffs } from "../types/tariffs";


export async function getTariffs() {
  const response = await authorizedRequests.get(
    BASE_URL + '/tariffs/installations/'
  );
  return response.data as Tariffs;
}