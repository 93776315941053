import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

type Props = {
  title: string;
  visible: boolean;
  hideNotification: () => void;
};

export const Notification: React.FC<Props> = ({
  title,
  visible,
  hideNotification
}) => {
  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    hideNotification();
  }

  const popup = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={visible}
      autoHideDuration={2000}
      onClose={handleClose}
      message={title}
      action={popup}
    />
  )
}