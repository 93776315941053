import axios from "axios";
import { getAccessToken, getRefreshToken, setTokens, updateToken } from "../helpers/jwtHelpers";
import { Tokens, UserData } from "../types/api";
import { BASE_URL } from "./constants";

export async function login(username: string, password: string) {
  const response = await axios.post(
    BASE_URL + '/auth/jwt/create/',
    { username, password }
  );

  const tokens = response.data as Tokens;
  setTokens(tokens);
}

export async function register(username: string, password: string) {
  await axios.post(
    BASE_URL + '/auth/users/',
    { username, password }
  );

  await login(username, password);
}

export const authorizedRequests = axios.create({
  baseURL: BASE_URL,
});


authorizedRequests.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

authorizedRequests.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // access token has expired
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refresh = getRefreshToken();
        const response = await axios.post(
          BASE_URL + '/auth/jwt/refresh/',
          { refresh }
        );
        const { access } = response.data;

        updateToken(access);

        originalRequest.headers.Authorization = `Bearer ${access}`;
        return axios(originalRequest);
      } catch (error) {
        console.log(error);
      }
    }

    return Promise.reject(error);
  }
);

export const getUserData = async () => {
  return (await authorizedRequests.get('auth/users/me/')).data as UserData;
}