import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import { PwaMainPage } from "./pages/PwaMainPage/PwaMainPage";
import { PwaForm } from "./pages/PwaForm/PwaForm";
import Registration from "./pages/Registration/Registration";
import { FinancePage } from "./pages/FinancePage/FinancePage";
import { GeneralStatisticsPage } from "./pages/StatisticsPages/GeneralStatisticsPage";
import { TariffsPage } from "./pages/TariffsPage/TariffsPage";
import { FAQ } from "./pages/FAQ/FAQ";
import { NotFoundPage } from "./pages/ErrorPage/NotFoundPage";
import {PixelAPI} from "./pages/PixelAPI/PixelAPI";
import { PixelForm } from "./pages/PixelForm/PixelForm";
import { ClicksStatisticsPage } from "./pages/StatisticsPages/ClicksStatisticsPage";
import { MainLayout } from "./components/MainLayout/MainLayout";
import { Documentation } from "./pages/Documentation/Documentation";
import { BalancePage } from "./pages/BalancePage/BalancePage";
import { useAppSelector } from "./store/hooks";
import { getUserPartners, getUserStatus } from "./store/reducers/userReducer";


function App() {
  const adminUser = useAppSelector(getUserStatus);
  const userPartner = useAppSelector(getUserPartners);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/signup" element={<Registration />} />
        <Route element={<MainLayout />} >
          <Route path="/" element={<PwaMainPage />} />
          <Route path="/create" element={<PwaForm />} />
          <Route path="/create-pixel" element={<PixelForm />} />
          <Route path="/edit/:pwaId" element={<PwaForm />} />
          {userPartner ? "" : <Route path="/finances" element={<FinancePage />} />}
          {adminUser ? <Route path="/balance" element={<BalancePage />} /> : ""}
          <Route path="/pixel-api" element={<PixelAPI />} />
          <Route path="/pixel-api/edit/:id" element={<PixelForm />} />
          <Route path="/statistics" element={<GeneralStatisticsPage />} />
          <Route path="/clicks-statistics" element={<ClicksStatisticsPage />} />
          <Route path="/tariffs" element={<TariffsPage/>} />
          <Route path="/faq" element={<FAQ/>} />
          <Route path="/documentation" element={<Documentation/>} />
          <Route path="*" element={<NotFoundPage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
