import {authorizedRequests} from "./auth";
import {BASE_URL} from "./constants";
import {
    CloaksOptions,
    Country,
    CreatePwaOptions,
    PaymentResponse,
    PreLanding,
    PwaCreate,
    PwaCreatedError,
    PwaDataGet,
    PWAGetListData,
    PwaState,
    PwaStatus,
    Status,
    UserWhiteIpsList,
    VerticalTemplate
} from "../types/api";
import {PaginatedResults} from "./helpers/types";
import {createSlice} from "@reduxjs/toolkit";

export async function getPrelandings() {
    const response = await authorizedRequests.get(
        BASE_URL + '/prelandings/'
    );

    return response.data as PreLanding[];
}


export async function getCountries() {
    const response = await authorizedRequests.get(
        BASE_URL + '/statistics/countries/'
    );

    return response.data as Country[];
}


export async function getVerticalTemplates(params: URLSearchParams) {
    const response = await authorizedRequests.get(
        BASE_URL + '/pwa/verticals/preview_template/?' + params
    );

    return response.data as PaginatedResults<VerticalTemplate[]>;
}


export async function getPreviewTemplateById(id: number) {
    const response = await authorizedRequests.get(
        BASE_URL + `/pwa/verticals/preview_template/${id}/`
    );

    return response.data as VerticalTemplate;
}

export async function getPwaOptions() {
    const response = await authorizedRequests.options(
        BASE_URL + '/pwa/pwa/'
    );

    return response.data as CreatePwaOptions;
}

export async function getPwaData(id: number) {
    const response = await authorizedRequests.get(
        BASE_URL + `/pwa/pwa/${id}/`
    );

    return response.data as PwaDataGet;
}

export async function postVerticalTemplate(data: {
    template_type: string,
    icon: string,
    screenshot_1: string,
    screenshot_2: string,
    screenshot_3: string,
    screenshot_4: string,
}) {
    const response = await authorizedRequests.post(
        BASE_URL + '/pwa/verticals/preview_template/',
        data
    );

    return response.data as { id: number };
}

export async function deleteVerticalTemplate(id: number) {
    await authorizedRequests.delete(BASE_URL + `/pwa/verticals/preview_template/${id}/`);
}

export async function sendPwa(pwaData: PwaCreate, delaySetCreatePwaPending: (value: boolean) => void) {
    delaySetCreatePwaPending(true)
    const response = await authorizedRequests.post(
        BASE_URL + '/pwa/pwa/',
        pwaData
    );
    delaySetCreatePwaPending(false)

    return response.data as PwaCreate & {
        status: Status,
        owner: number,
        created_at: string,
        updated_at: string,
    };
}

// const pwaSlice = createSlice({
//     name: 'pwa',
//     initialState: {
//         createPwaPending: false
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(sendPwa.pending, (state) => {
//                 state.createPwaPending = true;
//             })
//
//             // .addCase(sendPwa.pending, (state) => {
//             //     state.createPwaPending = true
//             // },
//     }
// })


export async function updatePwa(id: number, pwaData: PwaCreate, successUpdateData: () => void) {
    await authorizedRequests.put(
        BASE_URL + `/pwa/pwa/${id}/`,
        pwaData
    );
    successUpdateData()
}

export async function getCreatedPwaList(params: URLSearchParams, signal: AbortSignal) {
    const response = await authorizedRequests.get(
        BASE_URL + '/pwa/pwa/?' + params,
        {signal}
    );

    return response.data as PaginatedResults<PWAGetListData[]>;
}

export async function deletePWA(id: number) {
    await authorizedRequests.delete(BASE_URL + `/pwa/pwa/${id}/`);
}


export async function deleteBulkPWAs(ids: number[]) {
    return await authorizedRequests.delete(
        BASE_URL + "/pwa/pwa/delete_bulk/",
        {data: {ids}}
    );
}


export async function changePwaStatus(id: number, state: PwaState) {
    const response = await authorizedRequests.post(
        BASE_URL + `/pwa/pwa/${id}/change_state/`,
        {state}
    );

    return response.data as PwaStatus;
}

export async function changeBulkPWAsStatus(ids: number[], state: PwaState) {
    await authorizedRequests.post(
        BASE_URL + `/pwa/pwa/change_state_bulk/`,
        {ids, state}
    );
}


export async function getPWAPayment(id: number) {
    const response = await authorizedRequests.get(
        BASE_URL + `/pwa/pwa/${id}/payment/`
    );

    return response.data as PaymentResponse;
}

export async function getUserWhiteIps() {
    const response = await authorizedRequests.get(
        BASE_URL + '/pwa/white-ips/'
    );

    return response.data as UserWhiteIpsList;
}

export async function getCloaksOptions() {
    const response = await authorizedRequests.options(
        BASE_URL + '/pwa/cloaks/'
    );

    return response.data as CloaksOptions;
}

export async function duplicatePWA(id: number) {
    await authorizedRequests.get(
        BASE_URL + `/pwa/pwa/${id}/duplicate/`
    );
}
