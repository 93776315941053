import { FC } from "react";

import sortIconActive from "../../img/sortVertical.svg";
import sortIconNotActive from "../../img/sortNotActive.svg";
import styles from "./SortIcon.module.scss";

type SortIconProps = {
  onClick: () => void,
  isActive: boolean,
  isDesc: boolean,
}

export const SortIcon: FC<SortIconProps> = ({ onClick, isActive, isDesc }) => {
  return <img
    width={32}
    height={24}
    onClick={onClick}
    src={isActive ? sortIconActive : sortIconNotActive}
    alt="sort icon"
    className={`${styles.sortIcon} ${isDesc && styles.sortIconRotated}`}
  />;
}