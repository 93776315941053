import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import styles from "./FinancePage.module.scss";

import filterIcon from "../../img/filterIcon.svg";
import { RefillTable } from "../../components/Tables/RefillTable";
import { BalanceRefill } from "../../components/Modals/BalanceRefill/BalanceRefill";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
  getTransactionsData, getTransactionsThunk,
} from "../../store/reducers/financeReducer";
import { WriteOffTable } from "../../components/Tables/WriteOffTable";
import { ResetFiltersButton } from "../../components/ResetFiltersButton/ResetFiltersButton";
import { useSearchParams } from "react-router-dom";
import { ERROR, params } from "../../api/helpers/constants";
import { TransactionPurpose } from "../../types/finance";
import { ParamsRowSelect } from "../../components/Select/ParamsRowSelect";
import { getUserStatus } from "../../store/reducers/userReducer";
import { ExportReportModal } from "../../components/Modals/ExportReportModal/ExportReportModal";
import { getExportTransactionLink } from "../../api/finance";
import { DateSelect } from "../../components/Select/DateSelect";


export const FinancePage = () => {
  const adminUser = useAppSelector(getUserStatus);
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);

  const [showExportTransactionsModal, setShowExportTransactionsModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getTransactionsData());
  }, [dispatch]);

  useEffect(() => {
    const promise = dispatch(getTransactionsThunk());

    promise.unwrap().catch(error => {
      if (error.detail === ERROR.INVALID_PAGE_ERROR) {
        setSearchParams(_params => {
          _params.set(params.page, '1');
          return _params;
        });
      }
    });

    return () => {
      promise.abort();
    }

  }, [dispatch, searchParams]);

  function handleResetFilters() {
    setSearchParams(new URLSearchParams());
  }


  return (
    <div>
      <div className="title-container">
        <h2>Финансы</h2>
        <div className={styles.topButtonsContainer}>
          {adminUser && <Button appear="outlineWithBorder" onClick={() => {
            setShowExportTransactionsModal(true)
          }}>Экспорт транзакций</Button>}
          <Button onClick={() => {
            setShowModal(true)
          }}>Пополнить</Button>
        </div>
      </div>
      <div className={styles.accountsContainer}>
        <button
          type="button"
          onClick={() => setSearchParams(_params => {
            _params.set(params.purpose, TransactionPurpose.refill);
            _params.set(params.page, '1');
            return _params;
          })}
          className={
            (searchParams.get(params.purpose) === TransactionPurpose.refill
              || !searchParams.get(params.purpose)) ? styles.active : ""
          }
        >
          Пополнение
        </button>
        <button
          type="button"
          onClick={() => setSearchParams(_params => {
            _params.set(params.purpose, TransactionPurpose.write_off);
            _params.set(params.page, '1');
            return _params;
          })}
          className={searchParams.get(params.purpose) === TransactionPurpose.write_off ? styles.active : ""}
        >
          Списание
        </button>
      </div>
      <div className="filters-panel">
        <div className={styles.leftSide}>
          <img src={filterIcon} width={28} height={28} alt="filter icon"/>
          <DateSelect />
        </div>
        {(searchParams.get(params.date_before)
            || searchParams.get(params.date_after))
          && <ResetFiltersButton onClick={handleResetFilters} className={styles.resetFilters}/>}
        <div className={styles.rightSide}><ParamsRowSelect/></div>
      </div>
      {searchParams.get(params.purpose) === TransactionPurpose.write_off
        ? <WriteOffTable/>
        : <RefillTable/>}
      {showModal && <BalanceRefill hideModal={() => setShowModal(false)}/>}
      {showExportTransactionsModal && <ExportReportModal
        downloadLink={getExportTransactionLink(searchParams)}
        hideModal={() => setShowExportTransactionsModal(false)}
        fileName="finance"
      />}
    </div>

  );
};
