import { BASE_URL } from "./constants";
import { PaymentMessage } from "./helpers/websocketTypes";
import { getAccessToken } from "../helpers/jwtHelpers";
import { Options } from "react-use-websocket";

function getWebSocketBaseUrl() {
  // https://pwawwtes.site/api (BASE_URL) => wss://pwawwtes.site/websocket
  return BASE_URL
    .replace('https:', 'wss:')
    .replace('http:', 'wss:')
    .replace('/api', '/websocket');
}

type WsEventData<T> = {
  event_name: string,
  message: T
};

/// GLOBAL
export const wsOptions: Options = {
  share: true, // share existing connection to WebSocket with same connection URL
  shouldReconnect: () => true,
};

/// PAYMENT
export const getPaymentWebSocket = () => getWebSocketBaseUrl() + '/pwa/payment/?token=' + getAccessToken();
export function decodePaymentEvent(event: MessageEvent) {
  const { message } = JSON.parse(event.data) as WsEventData<PaymentMessage>;

  return message;
}