import { Link } from "react-router-dom";

import styles from "./ErrorPage.module.scss";
import notFoundImg from "../../img/404.png"

export const NotFoundPage = () => {

  return (

    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.title}>Ошибка 404</div>
        <div className={styles.title}>Страница не найдена</div>
        <div className={styles.subtitle}>К сожалению, страница, которую вы ищете, недоступна. Вернуться на
          <Link to='/' className={styles.link}> Главную</Link>
        </div>
      </div>
      <img src={notFoundImg} alt="not found" className={styles.image404}/>
    </div>

  )
}