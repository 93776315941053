import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import { useState, useEffect } from 'react';
import { getLanguages } from "../api/localization";
import { SelectList } from "../types/global";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


const useLanguages = () => {
  const [languages, setLanguages] = useState<SelectList>([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const res = await getLanguages();
        const languageSelectOptions = res.map(item => ({
          value: item.id.toString(),
          label: item.language
        }));
        setLanguages(languageSelectOptions);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

     fetchLanguages();

  }, []);

  return languages;
};

export default useLanguages;


export const useLanguagesCode = () => {
  const [languages, setLanguages] = useState<SelectList>([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const res = await getLanguages();
        const languageSelectOptions = res.map(item => ({
          value: item.language_code,
          label: item.language
        }));
        setLanguages(languageSelectOptions);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();

  }, []);

  return languages;
};

