
export function formatErrors(data: any) {
  const res: any = {};
  if (typeof data === 'string') return res;

  for (const key in data) {
    if (
      Array.isArray(data[key])
      && data[key].length !== 0
      && (typeof data[key][0] === 'string')
    ) {
      res[key] = data[key][0];
    } else {
      res[key] = formatErrors(data[key]);
    }
  }

  return res;
}
