import { FormikHelpers } from "formik";
import * as Yup from 'yup';
import { patchPixelData, sendPixel } from "../../../api/pixelApi";
import { formatErrors } from "../../../helpers/globalFormikHelpers";
import { PixelDataGet } from "../../../types/pixelApi";

export type PixelFormValues = {
  pixel_id: string,
  token: string,
}

export const pixelFormInitialValues: PixelFormValues = {
  pixel_id: "",
  token: "",
}

export const PixelFormValidationSchema = Yup.object().shape({
  pixel_id: Yup.string()
    .required('Ввеидите пиксель')
    .matches(/^\d+$/, 'Поле должно содержать только цифры')
    .trim(),

  token: Yup.string()
    .required('Ввеидите токен')
    .matches(/^[a-zA-Z0-9]+$/, 'Поле должно содержать только латинские символы и цифры')
    .trim(),
});

export const pixelFormValidation = (userPixelList: PixelDataGet[], currentPixel?: number) => async (
  values: PixelFormValues,
) => {
  if (
    userPixelList
      .filter(pixel => pixel.id !== currentPixel)
      .some(pixel => pixel.pixel_id === values.pixel_id)
  ) {
    return { pixel_id: "Такой Pixel уже существует" };
  }
  return {};
}

export const pixelFormSubmitHandler = async (
  values: PixelFormValues,
  formikHelpers: FormikHelpers<PixelFormValues>,
  successCallback: () => void,
  id ?: number
) => {
  try {
    if (id) {
      await patchPixelData(id, values);
    } else {
      await sendPixel(values);
    }
    successCallback();
  } catch (response: any) {
    const formattedErrors = formatErrors(response.response.data);
    formikHelpers.setErrors(formattedErrors);
  }
}