import styles from "./PixelForm.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  pixelFormInitialValues,
  pixelFormSubmitHandler, pixelFormValidation,
  PixelFormValidationSchema,
  PixelFormValues
} from "./helpers/formikHelpers";
import FormInput from "../../components/FormInput";
import { Button } from "../../components/Button/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { getUserId } from "../../store/reducers/userReducer";
import { getPixelDataAPI } from "../../api/pixelApi";
import { getUserPixelList } from "../../store/reducers/pixelReducer";

export const PixelForm = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const formikRef = useRef<FormikProps<PixelFormValues>>(null);

  const userId = useAppSelector(getUserId);
  const userPixelData = useAppSelector(getUserPixelList);


  useEffect(() => {
    if (location.pathname.startsWith('/pixel-api/edit') && params.id && formikRef.current && userId !== -1) {
      getPixelDataAPI(params.id).then(async (pixelData) => {
        if (userId !== pixelData.owner) navigate('/');

        formikRef.current?.setFormikState({
          values: pixelData,
          errors: {},
          touched: {},
          isSubmitting: false,
          isValidating: false,
          submitCount: 0,
        });
      });
    }
  }, [location, params, formikRef, userId, navigate]);

  return (
    <>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Создать новый Pixel API</h2>
        <Formik
          innerRef={formikRef}
          initialValues={pixelFormInitialValues}
          validationSchema={PixelFormValidationSchema}
          validate={pixelFormValidation(userPixelData.data, Number(params.id))}
          onSubmit={async (values, formikHelpers) => {
            await pixelFormSubmitHandler(
              values,
              formikHelpers,
              () => setSuccess(true),
              params.id ? Number(params.id) : undefined
            );
          }}
        >
          {({
            errors,
            values,
            isSubmitting,
          }) => (
            <Form>
              <div className={styles.container}>
                <div>
                  <label>Pixel</label>
                  <FormInput name="pixel_id" placeholder="Pixel"/>
                </div>
                <div>
                  <label>Token</label>
                  <FormInput name="token" placeholder="Token"/>
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <Button
                  type="submit"
                  disabled={location.pathname === '/create-pixel' && (!values.pixel_id || isSubmitting || Object.keys(errors).length > 0)}
                >
                  {location.pathname === '/create-pixel' ? "Создать" : "Редактировать"}
                </Button>
                <Button appear="outline" onClick={() => navigate('/pixel-api')}>Отмена</Button>
              </div>
            </Form>
          )}
        </Formik>
        <>
          {success && navigate('/pixel-api')}
        </>
      </div>
    </>
  )
}