import React, { ChangeEvent, useRef, useState } from "react";
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import styles from "./GenerateCommentsModal.module.scss";
import { useCloseModal } from "../hooks";
import { useLanguagesCode } from "../../../store/hooks";
import { Checkbox } from "../../Checkbox/Checkbox";
import { StyledInput } from "../../FormInput/FormInput.styled";
import { TextArea } from "../../TextArea/TextArea";
import { Select } from "../../Select/Select";
import { getGeneratedComments } from "../../../api/localization";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../../../pages/PwaForm/helpers/formikHelpers";
import { convertDate } from "../../../helpers/formatData";
import { Loader } from "../../Loader/Loader";


type Props = {
  hideModal: () => void;
};


export const GenerateCommentModal: React.FC<Props> = ({ hideModal }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useCloseModal(modalRef, hideModal);

  const [commentsCount, setCommentsCount] = useState<string>("1");
  const { setFieldValue, values } = useFormikContext<PwaFormValues>();

  const [showLoader, setShowLoader] = useState(false);

  const [errors, setErrors] = useState<{
    count?: string,
    keywords?: string,
  }>({})

  const languages = useLanguagesCode();


  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [localizationLanguage, setLocalizationLanguage] = useState<string>("");
  const [textareaKeywords, setTextareaKeywords] = useState('');
  const [isPositive, setIsPositive] = useState(true);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaKeywords(event.target.value);
  };

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Генерация комментариев</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>


        <div className={styles.contentContainer}>
          <div>
            <label className={styles.inputLabel}>Количество комментариев</label>
            <StyledInput
              $isvalid={!errors.count}
              variant="standard"
              fullWidth
              InputProps={{ disableUnderline: true }}
              value={commentsCount}
              onChange={(e) => {
                setCommentsCount(e.target.value);
                if (Number(e.target.value) > 30) {
                  setErrors(prev => ({ ...prev, count: "Количество должно быть меньше 30" }));
                } else if (Number(e.target.value) < 0) {
                  setErrors(prev => ({ ...prev, count: "Количество должно быть положительным значением" }));
                } else if (Number(e.target.value) !== Math.round(Number(e.target.value))) {
                  setErrors(prev => ({ ...prev, count: "Количество должно быть целым числом" }));
                } else {
                  setErrors(prev => ({ ...prev, count: undefined }));
                }

                if (
                  (Number(e.target.value) === Math.round(Number(e.target.value))
                    && Number(e.target.value) < 30
                    && Number(e.target.value) > 0)
                  || e.target.value === ""
                ) {
                  setCommentsCount(e.target.value);
                }
              }}
              type="number"
              placeholder="Введите количество комментариев"
            />
            {errors.count && <div className={styles.error}>{errors.count}</div>}
          </div>

          <div>
            <label className={styles.inputLabel}>Ключевые слова </label>
            <TextArea bg="white" handleChange={handleChange} value={textareaKeywords}/>
          </div>

          <div>
            <label className={styles.inputLabel}>Локализация</label>
            <Select
              value={selectedLanguage}
              options={languages}
              isSearchable
              placeholder="Выберите язык"
              onChange={(e) => {
                setSelectedLanguage(e!.value);
                setLocalizationLanguage (e!.label);
              }}
            />
          </div>

          <div>
            <Checkbox
              checked={!isPositive}
              name="negative"
              onChange={() => setIsPositive(prev => !prev)}
            />
            <span className={styles.negativeComments}>Негативный комментарий </span>
          </div>

          <div className={styles.submitBtnContainer}>
            <Button
              type="button"
              disabled={!selectedLanguage}
              onClick={async () => {
                setShowLoader(true);
                const res = await getGeneratedComments({
                  amount: Number(commentsCount),
                  keywords: textareaKeywords,
                  localization: localizationLanguage,
                  is_positive: isPositive,
                });
                const currentLength = values.comments.length;
                res.result.map((comment, index) => {
                  setFieldValue(`comments.${currentLength + index}.author`, comment.author);
                  setFieldValue(`comments.${currentLength + index}.text`, comment.comment);
                  setFieldValue(`comments.${currentLength + index}.date`, convertDate((new Date()).toString()));
                });

                setShowLoader(false);
                hideModal();
              }}
            >
              Сгенерировать
            </Button>
          </div>
          {showLoader && <div className={styles.loaderContainer}>
            <div className={styles.loaderContainer_text}>Подождите пару пару минут идет генерация комментариев</div>
            <div><Loader/></div>

          </div>
          }

        </div>
      </div>

    </div>
  )
}