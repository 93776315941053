import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const StyledInput = styled(TextField)<{
  $isvalid: boolean;
}>`
.MuiInputBase-root {
  height: 47px !important;
  background-color: #fff; 
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  border: ${(props) => (props.$isvalid ? "none" : "1px solid red")};
  &:focus {
    border-color: none !important;
  }
`;