import { authorizedRequests } from "./auth";
import { BASE_URL } from "./constants";
import { Balances, CryptoWallet, Transaction, TransactionsOptions, Users } from "../types/finance";
import { PaginatedResults } from "./helpers/types";
import { getParamsStringNoPagination } from "./helpers/scripts";


export async function getFinanceTransactions(params: URLSearchParams, signal: AbortSignal) {
  const response = await authorizedRequests.get(
    BASE_URL + '/finance/transactions/?' + params,
    { signal }
  );
  return response.data as PaginatedResults<Transaction[]>;
}


export async function getFinanceTransactionsOptions() {
  const response = await authorizedRequests.options(
    BASE_URL + '/finance/transactions/'
  );
  return response.data as TransactionsOptions;
}

export async function getWalletBalance() {
  const response = await authorizedRequests.get(
    BASE_URL + '/finance/wallet/get_user_balance/'
  );
  return response.data as { user_balance: number };
}

export async function getCryptoWallet() {
  const response = await authorizedRequests.get(
    BASE_URL + '/finance/wallet/get_crypto_wallet/'
  );
  return response.data as CryptoWallet;
}

export async function getBalances(params: URLSearchParams, signal: AbortSignal) {
  const response = await authorizedRequests.get(
    BASE_URL + '/finance/balances/?' + params,
    { signal }
  );
  return response.data as PaginatedResults<Balances[]>;
}

export async function getAllUsers() {
  const response = await authorizedRequests.get(
    BASE_URL + '/users/'
  );
  return response.data as Users[];
}


export function getExportTransactionLink(params: URLSearchParams) {
  return BASE_URL + '/finance/transactions/export_transactions/?' + getParamsStringNoPagination(params);
}

export function getExportBalancesLink(params: URLSearchParams) {
  return BASE_URL + '/finance/balances/export_balances/?' + getParamsStringNoPagination(params);
}



