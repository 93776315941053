import React from "react";
import { useField } from "formik";

import styles from "./FormSelect.module.scss";
import { MultiSelect } from "../Select/MultiSelect";
import { MultiValue } from "react-select";


type Props = {
  options: { label: string; value: string }[];
  values?: number[];
  name: string;
  onChange?: (values: MultiValue<{ label: string, value: string }>) => void,
  placeholder?: string;
};

export const FormMultiSelect: React.FC<Props> = ({
  name,
  values,
  options,
  placeholder,
  onChange,
}) => {
  const [field, meta, helpers] = useField(name);

  return (

    <>
      <MultiSelect
        placeholder={placeholder}
        values={options.filter(option => (values || field.value)
          .includes(Number(option.value)))}
        options={options}
        onChange={onChange || (values => {
          if(values=== undefined) {
            return [];
          }
          return helpers.setValue(values.map(value => Number(value.value)));
        })}
        onBlur={() => helpers.setTouched(true)}
      />
      {meta.touched && meta.error && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </>
  );
};
