import React, { useEffect, useMemo, useState } from "react";

import { Button } from "../../components/Button/Button";
import { Search } from "../../components/Search/Search";
import styles from "./PwaMainPage.module.scss";
import filterIcon from "../../img/filterIcon.svg";
import { Select } from "../../components/Select/Select";
import { filterStatusList } from "../../helpers/lists";
import { PwaTable } from "../../components/PwaTable/PwaTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  changeSelectedPWAsStatus, DEFAULT_ROWS_PER_PAGE,
  getCheckedPWAList, getCreatedPWAList,
  getPwaListData, selectNoPWA,
} from "../../store/reducers/pwaListReducer";
import { useDebounce } from "../../hooks/useDebounce";
import {
  getCountriesOptions, getFiltersThunk, getGlobalsFilters,
  getPwaEnumsStatus,
  getPwaEnumsVerticalPreviewTemplate,
} from "../../store/reducers/globalDataReducer";
import { PwaState } from "../../types/api";
import { ActivatePWAModal } from "../../components/Modals/ActivatePWAModal/ActivatePWAModal";
import { DeletePWAModal } from "../../components/Modals/DeletePWAModal/DeletePWAModal";
import { ResetFiltersButton } from "../../components/ResetFiltersButton/ResetFiltersButton";
import { MultiSelect } from "../../components/Select/MultiSelect";
import { ParamsRowSelect } from "../../components/Select/ParamsRowSelect";
import { ParamsPagination } from "../../components/Pagination/ParamsPagination";
import { params } from "../../api/helpers/constants";


export const PwaMainPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pwaList = useAppSelector(getCreatedPWAList);
  const statusOptions = useAppSelector(getPwaEnumsStatus);
  const checkedPwaList = useAppSelector(getCheckedPWAList);
  const countriesList = useAppSelector(getCountriesOptions);
  const verticalPreviewTemplateEnum = useAppSelector(getPwaEnumsVerticalPreviewTemplate);
  const globalFilters = useAppSelector(getGlobalsFilters);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getFiltersThunk());
  }, [dispatch]);

  useEffect(() => {
    dispatch(selectNoPWA());
   const promise =  dispatch(getPwaListData());

    return () => {
      promise.abort();
    }
  }, [dispatch, searchParams]);

  const [name, setName] = useDebounce((name) =>
    setSearchParams(_params => {
      if(name) {
        _params.set(params.search, name);
        _params.set(params.page, '1');
      } else if (!name) {
        _params.delete(params.search);
        _params.set(params.page, '1');
      }
      return _params;
    }),
    searchParams.get(params.search) || "",
  );

  const [showActivatePWAModal, setShowActivatePWAModal] = useState(false);
  const [showDeletePWAModal, setDeletePWAModal] = useState(false);
  const countriesListOptions = useMemo(() => {
    return countriesList
      .map(country => ({
        label: country.name,
        value: country.name
      }));
  }, [countriesList]);

  function handleResetFilters() {
    setSearchParams(new URLSearchParams());
    setName("");

  }

  const filteredStatusOptions = statusOptions.filter(status => status.value !== "archived" && status.value!=="error");

  const [
    selectedDomains,
    selectedCountries,
  ] = useMemo(() => {
    return [
      params.domain,
      params.country,
    ].map(
      paramName => searchParams.get(paramName)
        ?.split(',')
        .filter(value => value !== '')
        .map(value => ({ label: value, value })) || []
    );
  }, [searchParams]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className="title-container">
          <h2>PWA</h2>
          <Button onClick={() => navigate("/create")}>Создать PWA</Button>
        </div>
        <div className={styles.settingsBlock}>
          <div className={styles.filtersBlock}>
            <img src={filterIcon} width={28} height={28} alt="filter icon"/>
            <div className={styles.filterContainer}>
              <MultiSelect
                values={selectedDomains}
                options={globalFilters.domain}
                onChange={(values) => setSearchParams(_params => {
                  _params.set(params.domain, values.map(el => el.value).join(','));
                  _params.set(params.page, '1');
                  return _params;
                })}
                placeholder="Выберите домен"
              />
            </div>
            <div className={styles.filterContainer}>
              <Select
                value={searchParams.get(params.status)}
                placeholder="Статус"
                options={filteredStatusOptions}
                onSet={(value) => {
                  setSearchParams(_params => {
                    _params.set(params.status, value);
                    _params.set(params.page, '1');
                    return _params;
                  });
                }}
              />
            </div>
            <div className={styles.filterContainer}>
              <Select
                value={searchParams.get(params.vertical)}
                placeholder="Выберите вертикаль"
                options={verticalPreviewTemplateEnum}
                onSet={(value) => {
                  setSearchParams(_params => {
                    _params.set(params.vertical, value);
                    _params.set(params.page, '1');
                    return _params;
                  });
                }}
              />
            </div>
            <div className={styles.filterContainer}>
              <MultiSelect
                values={selectedCountries}
                options={countriesListOptions}
                onChange={(values) => setSearchParams(_params => {
                  _params.set(params.country, values.map(el => el.value).join(','));
                  _params.set(params.page, '1');
                  return _params;
                })}
                placeholder="Выберите страны"
              />
            </div>
            {(searchParams.get(params.vertical)
              || searchParams.get(params.status)
              || searchParams.get(params.domain)
              || searchParams.get(params.country)
            ) && <ResetFiltersButton onClick={handleResetFilters}/>}
          </div>

        </div>
        <div className={styles.tableSettings}>
          <div className={styles.rightSide}>
            <div className={styles.groupAction}>
              <Select
                isDisabled={checkedPwaList.size === 0}
                placeholder={checkedPwaList.size === 0 ? "Выберите PWA" : "Выберите действие"}
                options={filterStatusList}
                onSet={(value) => {
                  if (value === "Удалить") {
                    setDeletePWAModal(true);
                  }
                  if (value === "Оплатить") {
                    setShowActivatePWAModal(true)
                  } else if (value === "Остановить") {
                    return dispatch(changeSelectedPWAsStatus(PwaState.stop));
                  }
                }}
              />
            </div>
            <Search value={name} placeholder="Введите название PWA" onChange={(newValue) => setName(newValue)}/>
          </div>
          <div><ParamsRowSelect /></div>
        </div>
        <div className={styles.tableContainer}>
          <PwaTable />
          <ParamsPagination
            listLength={pwaList.count}
            defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
          />
        </div>
      </div>
      {showActivatePWAModal && <ActivatePWAModal hideModal={() => setShowActivatePWAModal(false)}/>}
      {showDeletePWAModal && <DeletePWAModal
          hideModal={() => setDeletePWAModal(false)}
      />}
    </>
  );
};
