import React, { useRef } from "react";
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import styles from "./DeletePixelModal.module.scss";
import { useAppDispatch } from "../../../store/hooks";
import { deletePixelThunk } from "../../../store/reducers/pixelReducer";
import { useCloseModal } from "../hooks";

type Props = {
  id: number;
  hideModal: () => void;
};
export const DeletePixelModal: React.FC<Props> = ({id, hideModal}) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Удалить Pixel</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.warning}>Вы точно хотите удалить Pixel?</div>
          <div className={styles.info}>Удаленный Pixel будет невозможно восстановить</div>
        </div>
        <div className="modal-bottomWarapper">
          <Button
            onClick={() => {
              dispatch(deletePixelThunk(id))
              hideModal();
            }}
          >
            Удалить
          </Button>
          <button type="button" onClick={hideModal} className="modal-closeBtn">Отмена</button>
        </div>
      </div>
    </div>
  )
}