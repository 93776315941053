import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import InputSmall from "../../components/InputSmall/InputSmall";
import { register } from "../../api/auth";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import styles from "../../components/AuthLayout/AuthLayout.module.scss";
import { Button } from "../../components/Button/Button";
import { getErrorsObject } from "../../api/helpers";
import { getAccessToken, parseJwt } from "../../helpers/jwtHelpers";


const Registration: React.FC = () => {
  const navigate = useNavigate();
  const [registerError, setRegisterError] = useState('');

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      const parsedToken = parseJwt(accessToken);
      // if expiration date is in the future - redirect to main page
      if (parsedToken.exp > Number(new Date()) / 1000) {
        navigate('/', { replace: true });
      }
    }
  }, []);

  return (
    <AuthLayout
      title="Get Started"
      subtitle="Create your new account. Confirm information"
    >
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
        }}
        validate={values => {
          const errors: {
            email?: string,
            username?: string,
            password?: string,
          } = {};

          if (!values.email) errors.email = 'Required';
          if (!values.username) errors.username = 'Required';
          if (!values.password) errors.password = 'Required';

          return errors;
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            await register(values.username, values.password);
            navigate('/');
          } catch (error: any) {
            const errors = getErrorsObject(error);
            setErrors(errors);
            const errorMessage = error.response.data.detail;
            setRegisterError(errorMessage);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className={styles.content}>
            <div>
              <Field
                name="email"
                label="Email"
                placeholder="Enter email"
                as={InputSmall}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </div>
            <div>
              <Field
                name="username"
                label="Login"
                placeholder="Enter login"
                as={InputSmall}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
              />
            </div>
            <div>
              <Field
                name="password"
                label="Password"
                placeholder="Enter password"
                type="password"
                as={InputSmall}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                submitWithEnter
              />
            </div>
            <div>
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  width="162px"
                  height="47px"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Sign up
                </Button>
              </div>
              {registerError && <div className="errorMessage">{registerError}</div>}
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>

  );
}

export default Registration;