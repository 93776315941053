import React from "react";
import styles from "./ResetFiltersButton.module.scss";
import smallCloseRed from "../../img/smallCloseRed.svg";

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
};

export const ResetFiltersButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <div onClick={onClick} className={`${styles.resetFilters} ${className}`}>
      Очистить
      <img src={smallCloseRed} alt="close icon"/>
    </div>
  );
};
