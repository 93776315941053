import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllDescriptionsApi, getDescriptionsByIdApi, getDescriptionTranslation } from "../../api/localization";
import { RootState } from "../store";


type InitialState = {
  allDescriptions: {
    id: number, description: string
  }[],
  selectedDescription: { id: number, description: string },
  translationData: ThunkTranslationResponse,
  pendingStatus: string,
}

type TranslationParams = {
  id: number;
  language_id: number;
};

type ThunkTranslationResponse = {
  success: boolean,
  translated_description: string
}

const initialState: InitialState = {
  allDescriptions: [],
  selectedDescription: { id: -1, description: "" },
  translationData: { success: false, translated_description: "" },
  pendingStatus: "",
}

export const getAllDescriptionsThunk = createAsyncThunk(
  "localizationReducer/getAllDescriptionsThunk",
  async () => {
    return await getAllDescriptionsApi();
  },
)

export const getDescriptionByIdThunk = createAsyncThunk(
  "localizationReducer/getDescriptionByIdThunk",
  async (id: number) => {
    return await getDescriptionsByIdApi(id);
  },
)

export const getDescriptionTranslationThunk = createAsyncThunk<ThunkTranslationResponse, TranslationParams>(
  "localizationReducer/getDescriptionTranslationThunk",
  async ({ id, language_id }) => {
    return await getDescriptionTranslation(id, language_id);
  }
);


const localizationReducer = createSlice({
  name: "localizationReducer",
  initialState,
  reducers: {
    resetSelectedDescription: (state) => {
      state.selectedDescription = initialState.selectedDescription;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDescriptionsThunk.fulfilled, (state, action) => {
      state.allDescriptions = action.payload;
    });

    builder.addCase(getDescriptionByIdThunk.fulfilled, (state, action) => {
      state.selectedDescription = action.payload;
    });

    builder.addCase(getDescriptionTranslationThunk.fulfilled, (state, action) => {
      state.translationData.success = action.payload.success;
      state.translationData.translated_description = action.payload.translated_description;
      state.pendingStatus= "fulfilled";
    });
    builder.addCase(getDescriptionTranslationThunk.pending, (state, action) => {
      state.pendingStatus = "pending";
    });
  }
})

export const getAllDescriptions = (state: RootState) => state.localizationReducer.allDescriptions;
export const getSelectedDescription = (state: RootState) => state.localizationReducer.selectedDescription;
export const getTranslatedDescription = (state: RootState) => state.localizationReducer.translationData;
export const getTranslationStatus = (state: RootState) => state.localizationReducer.pendingStatus;

export const { resetSelectedDescription } = localizationReducer.actions;

export default localizationReducer.reducer;