import React, { useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";

import styles from './ActivatePWAModal.module.scss';
import closeIcon from "../../../img/closeIcon.svg";
import walletIcon from "../../../img/wallet.svg";
import { Button } from "../../Button/Button";
import { useAppDispatch } from "../../../store/hooks";
import { ActivateStatus, getPwaListData, payForPWA, payForSelectedPWAs, } from "../../../store/reducers/pwaListReducer";
import celebrationIcon from "../../../img/celebration.svg";
import noDomainsIcon from "../../../img/noDomains.svg"
import { Loader } from "../../Loader/Loader";
import { useCloseModal } from "../hooks";
import { decodePaymentEvent, getPaymentWebSocket, wsOptions } from "../../../api/webSocket";
import { PaymentErrorStatus } from "../../../api/helpers/websocketTypes";

type Props = {
  hideModal: () => void;
  pwaId?: number,
};

const HIDE_MODAL_DELAY = 2000;

export const ActivatePWAModal: React.FC<Props> = ({ hideModal, pwaId }) => {
  const dispatch = useAppDispatch();

  const { lastMessage } = useWebSocket(getPaymentWebSocket, wsOptions);

  useEffect(() => {
    if (!lastMessage) return;

    const paymentData = decodePaymentEvent(lastMessage);

    if (paymentData.data.id === pwaId || pwaId === undefined) {
      if (paymentData.status === 'success') {
        setActivation({ status: ActivateStatus.Success });
      } else {
        switch (paymentData.error_status) {
          case PaymentErrorStatus.already_paid:
            setActivation({ status: ActivateStatus.AlreadyPaid, message: paymentData.error_message });
            break;
          case PaymentErrorStatus.not_enough_balance:
            setActivation({ status: ActivateStatus.NotEnoughMoney, message: paymentData.error_message });
            break;
          case PaymentErrorStatus.service_connection_error:
          case PaymentErrorStatus.no_domains_available:
          default:
            setActivation({ status: ActivateStatus.Error, message: paymentData.error_message });
            break;
        }
      }
    }
  }, [lastMessage]);

  const [activation, setActivation] = useState<{
    status: ActivateStatus,
    message?: string
  }>({ status: ActivateStatus.Waiting });

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (activation.status === ActivateStatus.Processing) {
      timeout = setTimeout(() => hideModal(), HIDE_MODAL_DELAY);
    }

    return () => clearTimeout(timeout);
  }, [activation]);

  useCloseModal(modalRef, () => {
    hideModal();
    dispatch(getPwaListData());
  });

  const paymentHandler = async () => {
    if (pwaId) {
      await dispatch(payForPWA(pwaId));
      setActivation(prev => prev.status === ActivateStatus.Waiting
        ? { status: ActivateStatus.Processing }
        : prev
      );
    } else {
      const { payload: result } = await dispatch(payForSelectedPWAs());
      if (result === ActivateStatus.AlreadyPaid) {
        setActivation({ status: ActivateStatus.AlreadyPaid });
      } else {
        setActivation(prev => prev.status === ActivateStatus.Waiting
          ? { status: ActivateStatus.Processing }
          : prev
        );
      }
    }
  }

  let content = <></>;

  switch (activation.status) {
    case ActivateStatus.Waiting:
      content = <>
        <div className={styles.warning}>После активации PWA изменить домен или Sub домен невозможно!</div>
        <div className={styles.warningInfo}>Активация PWA стоит $6, также за каждую установку PWA будет взиматься плата
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={paymentHandler}>Активировать</Button>
          <button type="button" onClick={hideModal} className="modal-closeBtn">Отмена</button>
        </div>
      </>;
      break;
    case ActivateStatus.Processing:
      content = <>
        <div className={styles.warning}>После активации PWA изменить домен невозможно!</div>
        <div className={styles.warningInfo}>Продолжите пару пару минут идет создание PWA</div>
        <Loader/>
      </>;
      break;
    case ActivateStatus.NotEnoughMoney:
      content = <>
        <img alt="wallet icon" src={walletIcon} className={styles.icon}/>
        <div className={styles.warningInfo}>На вашем счету недостаточно средств для активации PWA, пополните баланс
          и повторите попытку активации еще раз
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={hideModal}>Продолжить</Button>
        </div>
      </>;
      break;
    case ActivateStatus.AlreadyPaid:
      content = <>
        <img alt="wallet icon" src={walletIcon} className={styles.icon}/>
        <div className={styles.warningInfo}>Выбранные PWA уже оплачены
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={hideModal}>Продолжить</Button>
        </div>
      </>;
      break;
    case ActivateStatus.Error:
      content = <>
        <img alt="planet" src={noDomainsIcon} className={styles.icon}/>
        <div className={styles.warningInfo}>Ошибка: {activation!.message}
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={hideModal}>На главную</Button>
        </div>
      </>;
      break;
    default:
    case ActivateStatus.Success:
      content = <>
        <img alt="success" src={celebrationIcon} className={styles.icon}/>
        <div className={styles.warningInfo}>
          Ваш PWA успешно Активирован, нажмите <br/> “Продолжить” что бы перейти к списку ваших PWA
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={hideModal}>Продолжить</Button>
        </div>
      </>;
      break;
  }

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Активация PWA</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        {content}
      </div>
    </div>
  )
}