import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import { login } from '../../api/auth';
import styles from "../../components/AuthLayout/AuthLayout.module.scss";
import InputSmall from "../../components/InputSmall/InputSmall";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { Button } from "../../components/Button/Button";
import { getErrorsObject } from "../../api/helpers";
import { getAuthStatus, resetAuthStatus } from "../../store/reducers/userReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAccessToken, parseJwt } from "../../helpers/jwtHelpers";


const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');

  const authStatus = useAppSelector(getAuthStatus);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      const parsedToken = parseJwt(accessToken);
      // if expiration date is in the future - redirect to main page
      if (parsedToken.exp > Number(new Date()) / 1000) {
        navigate('/', { replace: true });
      }
    }
    dispatch(resetAuthStatus());
  }, [authStatus]);

  return (
    <AuthLayout
      title="Welcome Back"
      subtitle="Enter your email and password to access your account"
      showSignUpLink
    >
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validate={values => {
          const errors: {
            username?: string,
            password?: string,
          } = {};

          if (!values.username) errors.username = 'Required';
          if (!values.password) errors.password = 'Required';

          return errors;
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            await login(values.username, values.password);
            navigate('/');
          } catch (error: any) {
            const errors = getErrorsObject(error);
            setErrors(errors);
            const errorMessage = error.response.data.detail;
            setLoginError(errorMessage);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className={styles.content}>
            <div>
              <Field
                tabIndex={1}
                name="username"
                label="Login"
                placeholder="Enter login"
                as={InputSmall}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
              />
            </div>
            <div>
              <Field
                tabIndex={2}
                name="password"
                label="Password"
                placeholder="Enter password"
                type="password"
                as={InputSmall}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                submitWithEnter
              />
            </div>
            <div>
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  width="162px"
                  height="47px"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Log in
                </Button>
              </div>
              {loginError && <div className="errorMessage">{loginError}</div>}
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
}

export default Login;