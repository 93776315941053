import { useFormikContext } from "formik";

import styles from "./Tracker.module.scss";
import { FormInput } from "../../../components/FormInput/FormInput";
import { PwaFormValues } from "../helpers/formikHelpers";
import { PagesNavigation } from "../PagesNavigation/PagesNavigation";
import React from "react";

function Tracker() {
  const { values, errors } = useFormikContext<PwaFormValues>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <div className={styles.columnsContainer}>
          <div className={styles.column}>
            <div className={styles.row}>
                <div className={styles.inputContainer}>
                  <label>Ссылка на оффер/трекер</label>
                  <FormInput
                    name="tracker.offer_url"
                    placeholder="Введите ссылку"
                  />
                  <div className={styles.info}> Обязательно передайте значение &#123;tds_clickid&#125; в одном из своих параметров</div>
                </div>
              {/*<div className={styles.inputContainer}>*/}
              {/*  <label>Traffic back</label>*/}
              {/*  <FormInput*/}
              {/*    name="tracker.traffic_back"*/}
              {/*    placeholder="Введите ссылку"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <PagesNavigation
          nexButtonDisabled={!values.tracker.offer_url || !!errors.tracker?.offer_url}
        />
      </div>
    </div>
  );
}

export default Tracker;
